<!--
 * @Author: sqk
 * @Date: 2020-08-10 15:39:59
 * @LastEditTime: 2023-12-19 10:34:33
 * @LastEditors: chuzhengyang.ex chuzhengyang.ex@hx-partners.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\layout\TopDownLayout\Content.vue
-->

<template>
  <section class="app-main" :class="{'noPadding' : isPhone}">
    <!-- main-content -->
    <!-- <transition
      name="el-fade-in-linear"
      :duration="{ enter: 200, leave: 200 }"
      mode="out-in"
    >-->

    <div>
      <!-- 最多缓存一个组件 你可以按照需求自己设置 -->
      <!-- <keep-alive :max="1">
        <transition>
          <router-view v-if="$route.meta.keepAlive" :key='key'></router-view>
        </transition>
      </keep-alive>
      <transition name="fade">
        <router-view v-if="!$route.meta.keepAlive" :key='key'></router-view>
      </transition> -->
      <keep-alive>
          <router-view  v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>

    <!-- </transition> -->
  </section>
</template>

<script>
export default {
  name: "FrameContent",
  data() {
    return {
		isPhone: false
	};
  },
  mounted() {
  	let hash = window.location.hash;
	let flag = navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	);
	this.isPhone = flag && (hash.indexOf('/submitEndActivitySuccess') != -1 || hash.indexOf('questionnaire/answer') != -1 || hash.indexOf('/') != -1 || hash.indexOf('/privacy') !== -1 || hash.indexOf('/personalInfo') !== -1 || hash.indexOf('/thirdPartyInfo') !== -1 || hash.indexOf('/correctionError') !== -1 || hash.indexOf('/shopPersonalInfo') !== -1 || hash.indexOf('/shopThirdPartyInfo') !== -1 || hash.indexOf('/positionInformation') !== -1 || hash.indexOf('/telephonePermissions') !== -1 || hash.indexOf('/accessingPhotoAlbums') !== -1 || hash.indexOf('/accessCamera') !== -1)
  },
  computed: {
    key () {
      return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    }
  }
};
</script>

<style lang="less">
.app-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  padding-top: 114px;
  min-width: 1188px;
  > div > div.fadeIn {
    animation-duration: 0.5s;
  }
  &.noPadding{
    width: 100vw;
    min-width: auto;
  }

}
.noPadding {
	padding: 0;
}
// .fade-enter,
// .fade-leave-to{
//  visibility: hidden;
//  opacity: 0;
// }
// .fade-enter-active,.fade-leave-active{
//  transition: opacity 5s ease-out;
// }
// // .fade-leave-active{
// //   transition: all 0;
// // }
// // .fade-enter-to,
// // .fade-leave{
// //  visibility: visible;
// //  opacity: 1;
// // }
// .fade-enter-to,
// .fade-leave{
//  visibility: visible;
//  opacity: 1;
// }
.fade-enter,.fade-leave-to{
 visibility: hidden;
 opacity: 0;
}
// .fade-leave-to{
//  display: none;
// }
.fade-enter-active{
 transition: opacity 1.2s ease-out;
}
.fade-enter-to,
.fade-leave{
 visibility: visible;
 opacity: 1;
}
// .v-enter,
// .v-leave-to {
//   opacity: 0; //透明度,0代表完全透明,1完全不透明
// }
// .v-enter-active {
//   transition: all 1.2s ease-out; 
// }
// .v-leave-active {
//   transition: all 0s ease-out;
// }
</style>
