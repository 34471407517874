<!--
 * @Author: sqk
 * @Date: 2020-08-10 15:39:59
 * @LastEditTime: 2024-08-26 13:50:46
 * @LastEditors: 尚宏浩 aaa@hisense.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\layout\TopDownLayout\Frame.vue
-->
<template>
	<div class="app-wrapper" :class="{firstLogin:!loginLaterFlag}">
		<a-spin :spinning="filterLoadFlag" size="large">
			<FrameHeader v-if="!$route.meta.isMenuHide"></FrameHeader>
			<FrameMenu @changeLoading="(data) => filterLoadFlag = data" v-if="!$route.meta.isMenuHide"></FrameMenu>
			<tool-bar v-if="$route.meta.isBarShow" :key="$route.meta.title"></tool-bar>
			<FrameContent></FrameContent>
			<FrameFooter v-if='!isPhone'></FrameFooter>
		</a-spin>
	</div>
</template>
<script>
// import { FrameHeader, FrameMenu, FrameContent } from "./index";
import {
	FrameHeader,
	FrameMenu,
	FrameContent,
	FrameFooter
} from "./index";
// FrameFooter
export default {
	name: "Frame",
	components: {
		FrameHeader,
		FrameMenu,
		FrameContent,
		FrameFooter
	},
	data() {
		return {
			isPhone: false,
			filterLoadFlag: false
			// loginLaterFlag:true
		};
	},
	computed: {
		loginLaterFlag() {
			return this.$store.state.user.loginLaterFlag ?
				this.$store.state.user.loginLaterFlag :
				localStorage.getItem("loginLaterFlag") ?
					JSON.parse(localStorage.getItem("loginLaterFlag")) :
					false;
		}
	},
	created() {
		// this.loginLaterFlag = localStorage.getItem('loginLaterFlag')
		this.isPhone = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
	},

};
</script>
<style lang="less" scoped>
.app-wrapper {
	position: relative;
	background-color: #F7F7F7;
	min-height: 100vh;

	// pointer-events: none;
	&.firstLogin {
		pointer-events: none;
	}
}
</style>
