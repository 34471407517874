<!--
 * @Author: sqk
 * @Date: 2020-08-10 15:39:59
 * @LastEditTime: 2021-08-04 19:17:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\layout\TopDownLayout\Header.vue
-->
<template>
  <div class="page-header clearfix">
    <div class="header-in">
      <!-- <router-link :to="{
                  name:'index'
              }">
        <div class="logo-box d-f-c">
          <img src="@/assets/home/logo.png" alt />
          <span>理想与信念助力我们飞得更远更高！</span>
        </div>
      </router-link>-->
      <div class="logo-box d-f-c">
        <span>{{
          titleMsg ? titleMsg : "理想与信念助力我们飞得更远更高！"
        }}</span>
        <!--<div class="change-v swiper-container" ref="changVSwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              当前为体验版，点击
              <i>切换旧版本</i>
            </div>
          </div>
        </div>-->
        <!-- <ul class="change-v">
          <li>
            当前为体验版，点击
            <i @click="toOld">切换旧版本</i>
          </li>
          <li>
            当前为体验版，点击
            <i @click="toOld">切换旧版本</i>
          </li>
        </ul>-->
      </div>
      <a-menu id="headNav" class="nav-box" v-model="current" mode="horizontal">
        
        <a-menu-item
          key="/login"
          class="login-item"
          :class="{ noLogin: isShowLogin }"
          v-if="isShowLogin"
          @click="navItemClick"
          >请登录</a-menu-item
        >
        <!-- <a-menu-item
          key="layout"
          class="item"
          v-if="!isShowLogin"
          @click="isLoginModalShow = true"
          >退出</a-menu-item
        > -->
        <a-menu-item
          key="/order/cis"
          class="item"
          v-if="showOrderCenter && !isShowLogin"
          @click="navItemClick"
          >订单中心</a-menu-item
        >
        <a-menu-item
          key="/channelOrder/orderList"
          class="item"
          v-if="showXsdd && !isShowLogin"
          @click="navItemClick"
          >销售订单</a-menu-item
        >
        <a-menu-item
          key="/stockCenter/myStock"
          class="item"
          v-if="showKccx&& !isShowLogin"
          @click="navItemClick"
          >库存查询</a-menu-item
        >
        <a-menu-item
          key="/center/todoList"
          class="item"
          v-if="!isShowLogin"
          @click="navItemClick"
          >待办({{dbTotalCount || 0}})</a-menu-item
        >
        <a-menu-item
          key="/center/loadCenter"
          class="item"
          v-if="!isShowLogin"
          @click="navItemClick"
          >下载中心</a-menu-item
        >
        <a-menu-item
          key="/favorites"
          class="item"
          v-if="shoucangjia && showActiveBox && !isShowLogin"
          @click="navItemClick"
          >收藏夹</a-menu-item
        >
        <!-- <a-menu-item key="all" class="item" v-if="!isShowLogin" @click="navItemClick">全部功能</a-menu-item> -->
        <!-- <a-menu-item class="item-more sjb-item" v-if="!isShowLogin">
          <i class="iconfont icon-shoujiban-dingbu"></i>
          手机版
          <a-menu-item class="sjb-box">
            <div class="code-box">
              <div class="item-left code-box">
                <img src="@/assets/common/code-wx-84.png" alt />
              </div>
              <div class="item-right">
                <h3>信天翁微信小程序</h3>
                <h4>微信扫一扫</h4>
                <div class="icon-box">
                  <div class="icon-item">
                    <i class="iconfont icon-weixin"></i>
                    <span>微信</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="code-box">
              <div class="item-left code-box">
                <img src="@/assets/common/code-az-84.png" alt />
              </div>
              <div class="item-right">
                <h3>信天翁APP</h3>
                <h4>安卓、iOS客户端</h4>
                <div class="icon-box">
                  <div class="icon-item">
                    <i class="iconfont icon-anzhuo"></i>
                    <span>安卓</span>
                  </div>
                  <div class="icon-item">
                    <i class="iconfont icon-ios"></i>
                    <span>ios</span>
                  </div>
                </div>
              </div>
            </div>
          </a-menu-item>
        </a-menu-item>-->
        <a-menu-item
          key="/cart"
          v-if="
            !isShowLogin &&
              showActiveBox &&
              (userInfo.account.loginSystem.indexOf('14168810880') != '-1' ||
                userInfo.account.loginSystem.indexOf('14168810879') != '-1')
          "
          class="item"
          @click="navItemClick($event)"
        >
          <i style="font-size: 12px!important" class="iconfont icon-shouye-gouwuche shoppingTrolley-gouwuche"></i>
          购物车({{ cart }})
          <!--<a-menu-item class="active">
            <div class="commodityEmpty" v-if="cart == '0'">
              <div>
                &lt;!&ndash; <i class="iconfont icon-gouwuche-weikong"></i> &ndash;&gt;
              </div>
              <p>您的购物车还没有商品，赶紧选购吧~</p>
            </div>
            <div class="commodityList" v-if="parseInt(cart) > 0" @click.stop>
              <h-scroll
                class="commodityBox"
                :settings="scrollSettings"
                @ps-scroll-y="scrollHanle"
              >
                &lt;!&ndash; <div class="commodityBox"> &ndash;&gt;
                &lt;!&ndash; <div class="commodity" v-for="(item, index) in cartObj.cartList" :key="index">
                  <div class="img-box">
                    <img src="../../assets/home/7.jpg" alt />
                  </div>
                  <div class="commodityPar">
                    <p class="commodityPar-left">XQG80-L121BS银滚筒洗衣机全自动</p>
                    <div class="commodityPar-right">
                      <div class="content-in">
                        <div class="top">s
                          <span class="price">1999元</span>
                          <span class="price2">1999元</span>
                        </div>
                        <div class="bottom">
                          <span>数量：x1</span>
                        </div>
                      </div>
                      <i class="iconfont icon-toubu-gouwuche-guanbi"></i>
                    </div>
                  </div>
                </div>&ndash;&gt;
                <a-spin :spinning="cartLoadding" size="large">
                  <div
                    class="commodity"
                    v-for="(item, index) in cartObj.cartList"
                    :key="index"
                    @click.stop="toDetailFun(item)"
                  >
                    <span class="count">
                      x
                      <i>{{ item.quantity }}</i>
                    </span>

                    <span
                      class="guanbigwc animated fadeIn"
                      @click.stop="deleteProduct(item.id, index)"
                      title="删除"
                    >
                      <i class="iconfont icon-guanbi-gouwuche"></i>
                    </span>

                    <div class="img-box">
                      <h-img
                        :imgUrl="item.picture"
                        :errorImg="
                          '/assets/new/images/product/' +
                            item.materialGroup +
                            '.jpg'
                        "
                        isUseBasePath
                      ></h-img>
                    </div>
                    <div class="commodityPar">
                      <div class="commodityPar-left">
                        <p class="info" :title="item.productName">
                          {{ item.productName }}
                        </p>
                        <p class="price">
                          {{ Number(item.price).toFixed(2) }}元
                        </p>
                      </div>
                    </div>
                  </div>
                </a-spin>
                &lt;!&ndash; </div> &ndash;&gt;
              </h-scroll>

              <div class="account" @click.stop>
                <span class="count-num">共{{ cartObj.size }}种商品</span>
                <span class="price">
                  <i>{{ Number(cartObj.totalPrice).toFixed(2) }}</i
                  >元
                </span>
                <h-btn
                  class="trolley_btn"
                  :width="130"
                  :height="38"
                  content="去购物车结算"
                  borderRadius="4"
                  @click="toCart"
                ></h-btn>
                &lt;!&ndash; <div class="trolley_btn">
                  <span>去购物车结算</span>
                </div>&ndash;&gt;
              </div>
            </div>
          </a-menu-item>-->
        </a-menu-item>
        <a-menu-item
          v-if="!isShowLogin"
          class="item-more user-slt"
          key="/basicInfo?type=1"
          @click="navItemClick"
        >
          <!-- <span slot="title">
            <span class="img d-f-c">
              <img src="@/assets/home/user-photo.png" alt />
            </span>

            信天翁用户
          </span>-->

          <span class="img d-f-c">
            <img src="@/assets/common/head-icon.png" alt />
          </span>
          {{ userInfo.account.userName }}
          <i class="iconfont icon-arrow-right-small-copy"></i>
          <a-menu-item class="user-slt-inf">
            <ul>
              <li>
                账号：
                <span>{{ userInfo.account.account }}</span>
              </li>
              <li>
                MDM编码：
                <span>{{ userInfo.customer.customerCode }}</span>
              </li>
              <li>
                全称：
                <span>{{ userInfo.customer.customerName }}</span>
              </li>
              <li>
                <p>合作品类：</p>
                <div :title="userInfo.matklInfo">{{ userInfo.matklInfo }}</div>
              </li>
              <li>
                <!-- 退出登录 -->
                <!-- <button class="exitLogin" @click="isLoginModalShow = true"> -->
                <button class="exitLogin" @click="userLayout">
                  
                  <i class="iconfont icon-tuichudenglu"></i>
                  退出登录
                </button>
              </li>
            </ul>
          </a-menu-item>

          <!-- <a-menu-item-group>
            <a-menu-item key="setting:1">切换用户</a-menu-item>
            <a-menu-item key="setting:2">退出登陆</a-menu-item>
          </a-menu-item-group> -->
        <!-- 
          <template slot="content">
      <img width="100px" src="../../../public/img/store_QrCode.png" alt="">
          </template>-->
        </a-menu-item>
      </a-menu>
    </div>

    <delete-modal
      :visible.sync="isLoginModalShow"
      info="确定退出登录？"
      @deleteHandOk="userLayout"
    ></delete-modal>
    <userModal
      :visible.sync="userAgreement"
      v-if="!userInfo"
    >
    </userModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { oldPublicPath } from "@/common/constant";
// 导入api接口
import {
  getCartNum, //获取购物车数量
  getCartInfoList, //获取购物车列表接口
  deleteProduct, addBrowsing //删除购物车产品
} from "@/utils/api.js";
import { menuList } from "@/utils/api.js";
import { getprivacyPolicyInfo, delegateList } from "@/views/home/api";
export default {
  name: "content-header",
  data() {
    return {
      isLoginModalShow: false,
      // 购物车列表 滚动条配置
      scrollSettings: {
        //如果此选项为 true，则当滚动到达边的末尾时，mousewheel 事件将传播到父元素。
        wheelPropagation: false
      },
      current: [""],
      isLogin: true, //是否登陆
      // cartObj: [] //购物车列表
      cartLoadding: false, //购物车加载标志
      isShopBoxCloseFlag: false,
      titleMsg: "", //头提示
      showActiveBox: false,
      shoucangjia: false,
      showOrderCenter: false,
      userAgreement:false,
      showXsdd:false,// 是否有销售订单权限
      showKccx:false,// 是否有库存查询权限
      showDb:false,// 是否有待办权限
      privacyPolicyInfor:{},
      // dbTotalCount:0, // 待办数量
    };
  },
  computed: {
    ...mapState({
      cart: state => state.user.cart,
      dbTotalCount: state => state.user.dbTotalCount,
      cartObj: state => state.user.cartObj,
      isShowLogin: state => (state.user.userInfo ? false : true),
      menuList: state =>
        state.user.menuList.filter(
          it => it.subMenuList && it.subMenuList.length > 0
        )
      //无子菜单 不添加
      // menuList: state =>[...state.user.menuList.filter(
      //     it => it.subMenuList && it.subMenuList.length > 0
      //   ),...state.user.menuList.filter(
      //     it => it.subMenuList && it.subMenuList.length == 0 & it.sourceName != '商品分类'
      //   )]
    }),
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    }
  },
  watch: {
    // userInfo(new,old) {
    //   if(JSON.stringify(new) != JSON.stringify(old)){
    //     alert(1)
    //   }
    // },
    // userInfo: {
    //   handler(newData, oldData) {
    //     if (JSON.stringify(newData) && JSON.stringify(newData) != JSON.stringify(oldData)) {
    //       //判断登陆状态
    //       alert(this.$route.path)
    //       if(this.$route.path == '/index'){
    //         alert(1)
    //         this.$driver.defineSteps(this.steps);
    //         this.$driver.start();
    //       }
    //       // this.$driver.defineSteps(this.steps);
    //       // this.$driver.start();
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  created() {
    if (this.userInfo) {
      // 修改获取菜单数据顺序
      this.getMenuList();
      //取消购物车列表
      //this.getCartInfoList();
      //获取购物车数量
      this.getCart();
      // this.getDelegateList(); // 获取待办数量 路由守卫中跳转获得最新 暂时不用
      const titleMsg = this.$configTotal({
        route: this.$route.fullPath,
        id: "1418000103"
      });
      this.titleMsg = titleMsg ? titleMsg.msgDesc : null;
    }
    
    this.initSwiper();

    this.getprivacyPolicy()
    

  },
  methods: {
    // 获取列表信息
    getMenuList() {
      //调用接口
      let params = { pageNo: 1, pageSize: 20, loginPlant: "WEBNEW" };
      menuList(params)
        .then(res => {
          if (res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            for (let i = 0; i < res.data.list.length; i++) {
              if (res.data.list[i].sourceName === "采购") {
                let twoItem = res.data.list[i];
                for (let a = 0; a < twoItem.subMenuList.length; a++) {
                  if (twoItem.subMenuList[a].sourceName === "海信订单") {
                    if (twoItem.subMenuList[a].subMenuList.length > 0) {
                      // console.log(twoItem.subMenuList[a].subMenuList);
                      let cc = twoItem.subMenuList[a].subMenuList;
                      for (let i = 0; i < cc.length; i++) {

                        if (cc[i].sourceName == "海信订单明细") {
                          this.showOrderCenter = true;
                          break
                        } else {
                          this.showOrderCenter = false;
                        }
                      }
                    }
                  }
                  // if (twoItem.subMenuList[a].sourceName === "渠道订单") {
                  //   if (twoItem.subMenuList[a].subMenuList.length > 0) {
                  //     // console.log(twoItem.subMenuList[a].subMenuList);
                  //     let cc = twoItem.subMenuList[a].subMenuList;
                  //     for (let i = 0; i < cc.length; i++) {
                  //       if (cc[i].sourceName == "渠道订单明细") {
                  //         this.showOrderCenter = true;
                  //         break
                  //       } else {
                  //         this.showOrderCenter = false;
                  //       }
                  //     }
                  //   }
                  // }
                }
              }


              if (res.data.list[i].sourceName === "销售") {
                let twoItem = res.data.list[i];
                for (let a = 0; a < twoItem.subMenuList.length; a++) {
                  if (twoItem.subMenuList[a].sourceName === "销售订单") {
                    let three = twoItem.subMenuList[a];
                    for (let bb = 0; bb < three.subMenuList.length; bb++) {
                      if (three.subMenuList[bb].sourceName === "销售订单（审核出库）") {
                        this.showXsdd = true;
                      }
                    }
                    break;
                  } else {
                    this.showXsdd = false;
                  }
                }
              }

              if (res.data.list[i].sourceName === "库存") {
                let twoItem = res.data.list[i];
                for (let a = 0; a < twoItem.subMenuList.length; a++) {
                  if (twoItem.subMenuList[a].sourceName === "库存查询") {
                    let three = twoItem.subMenuList[a];
                    for (let bb = 0; bb < three.subMenuList.length; bb++) {
                      if (three.subMenuList[bb].sourceName === "当前库存") {
                        this.showKccx = true;
                      }
                    }
                    break;
                  } else {
                    this.showKccx = false;
                  }
                }
              }

              if (res.data.list[i].sourceName === "个人中心") {
                let twoItem = res.data.list[i];
                for (let a = 0; a < twoItem.subMenuList.length; a++) {
                  if (twoItem.subMenuList[a].sourceName === "其他") {
                    let three = twoItem.subMenuList[a];
                    for (let bb = 0; bb < three.subMenuList.length; bb++) {
                      if (three.subMenuList[bb].sourceName === "购物车") {
                        this.showActiveBox = true;
                      } else if (
                        three.subMenuList[bb].sourceName === "收藏夹"
                      ) {
                        this.shoucangjia = true;
                        break;
                      } else {
                        this.showActiveBox = false;
                        this.shoucangjia = false;
                      }
                    }
                    break;
                  } else {
                    this.showActiveBox = false;
                    this.shoucangjia = false;
                  }
                }
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    //向上滚动轮播初始化
    initSwiper() {
      let this_ = this;
      this.$nextTick(() => {
        this.mySwiper = new this.$Swiper(this.$refs.changVSwiper, {
          on: {
            click: () => {
              this_.toOld();
            }
          },
          autoplay: {
            //自动轮播
            delay: 5000,
            disableOnInteraction: false
          },
          loop: true, // 循环模式选项
          direction: "vertical",
          onlyExternal: true
        });
        this_.mySwiper.el && (this_.mySwiper.el.onmouseover = function() {
          //鼠标放上暂停轮播
          this_.mySwiper.autoplay.stop();
        });
        this_.mySwiper.el && (this_.mySwiper.el.onmouseleave = function() {
          this_.mySwiper.autoplay.start();
        });
      });
    },
    //跳转老版本呢
    toOld() {
      window.open(oldPublicPath, "_self");
    },
    // 获取隐私政策数据
    getprivacyPolicy() {
      let data = {
        loginPlant: "PC"
      };
      return getprivacyPolicyInfo(data);
    },
    //菜单点击
    async navItemClick(i) {

      let res = await this.getprivacyPolicy()
      if(res.data.list.length > 0) {
        this.privacyPolicyInfor = res.data.list[0]
      }
      const { key } = i;
      console.log('i',i)
      let urlName = ''
      if(key == '/order/cis') {
        urlName='订单中心'
      }
      if(key == '/channelOrder/orderList') {
        urlName='销售订单'
      }
      if(key == '/stockCenter/myStock') {
        urlName='库存查询'
      }
      if(key == '/center/todoList') {
        urlName='待办'
      }
      if(key == '/center/loadCenter') {
        urlName='下载中心'
      }
      if(key == '/favorites') {
        urlName='收藏夹'
      }
      if(key == '/cart') {
        urlName='购物车'
      }

      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', key]);
        window._paq.push(['setDocumentTitle',urlName]);
        window._paq.push(['trackPageView']);
      }
      if(key == "/login") {
        // 如果缓存里没有获取不到 弹出弹窗
        if(!localStorage.getItem("agreePrivacy") ) {
          this.userAgreement = true
          return
        }

        //先获取缓存里是否已经同意  不同意弹出弹窗 同意跳转页面
        if( localStorage.getItem('agreePrivacy') == 'false') {
          this.userAgreement = true
          return
        }
        // 如果缓存里为true 版本号和当前接口调用中的不一样 也会弹出弹窗
        if(localStorage.getItem('agreePrivacy') == 'true' && localStorage.getItem('UserAgreementVersionNumber') !== this.privacyPolicyInfor.pageKey ){
          this.userAgreement = true
          return
        }
        else {
          this.$router.push({
            path: key
          });
          return
        }
      }
      if (
        key == "/basicInfo?type=1" ||
        key == "/order/cis" ||
        key == "/center/loadCenter" ||
        key == "/cart" ||
        key == "/favorites" ||
        key == "/channelOrder/orderList" ||
        key == "/stockCenter/myStock" ||
        key == "/center/todoList"
      ) {
        this.$router.push({
          path: key
        });
        if (key == "cart") {
          this.isShopBoxCloseFlag = true;
        }
      } else if (key == "all") {
        this.$message.success("待开发");
      }
      // showCommodity
    },
    //去购物车结算
    toCart() {
      this.$router.push({
        path: "/cart"
      });
    },
    //用户退出登陆
    // userLayout() {
    //   this.$confirm({
    //     content: "确定退出登录？",
    //     onOk: () => {
    //       this.$store
    //         .dispatch("user/logout")
    //         .then(() => {
    //           this.$message.success("退出成功!");
    //         })
    //         .catch(err => {
    //           console.error(err);
    //         });
    //     },
    //     onCancel() {}
    //   });
    // },
    userLayout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$message.success("退出成功!");
        })
        .catch(err => {
          console.error(err);
        });
    },
    //获取待办信息
    getDelegateList() {
      let params = {
        loginType: "CS",
        pageNo: 1,
        status: 0, // 0 -> 待办
        pageSize: 20
      };
      delegateList(params)
        .then((res) => {
          if (
            res.data.totalCount
          ) {
            this.$store.commit("user/SET_DB_TOTAL_COUNT", res.data.totalCount);
          } else {
            this.$store.commit("user/SET_DB_TOTAL_COUNT", 0);
          }
        })
        .catch((error) => {
          this.$store.commit("user/SET_DB_TOTAL_COUNT", 0);
          console.log(error);
        });
    },
    //获取购物车数量
    async getCart() {
      const cartNum = await getCartNum();
      this.$store.dispatch("user/cart", cartNum.data);
      // this.cart = cartNum.data;
    },
    //获取购物车列表
    async getCartInfoList() {
      try {
        this.cartLoadding = true;
        const res = await getCartInfoList();
        // console.log(res);
        if (res.data.code == "success") {
          // this.cartObj = res.data;
          this.$store.dispatch("user/cartObj", res.data);
          this.cartLoadding = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //删除购物车产品
    async deleteProduct(cartId) {
      try {
        const res = await deleteProduct({ cartId: cartId });
        if (res.data == "Y") {
          this.$message.success("删除成功!");
          this.getCart();
          // this.cartObj.cartList.splice(this.cartObj.cartList.findIndex(e => e.id == cartId), 1)
          // this.cartObj.cartList.splice(index,1);
          // console.log(this.cartObj.cartList)
          //改变数量状态
          // this.$store.dispatch("user/cart", this.cartObj.cartList.length);
          // this.cartObj.size --;
          this.getCartInfoList();
        }
      } catch (error) {
        console.log(error);
      }
    },

    //非菜单项（链接项）点击跳转
    menuItemClick(item) {
      let dataOld = {
        menuId:item.id,
        clientPlatform:'pc'
      }
      addBrowsing(dataOld).then(res => {
        // console.log('res',res);
      })
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', item.vuePath?item.vuePath:item.url]);
        window._paq.push(['setDocumentTitle', item.sourceName]);
        window._paq.push(['trackPageView']);
      }
      if (item.id == "14175010064" || item.subMenuList.length > 0) {
        if (item.targetType === "vue" && item.vuePath) {
          this.$router.push(item.vuePath);
        } else if (item.targetType === "blank" && item.url) {
          window.open(item.url, "_blank");
        }
      }
    },
    toDetailFun(item) {
      this.isShopBoxCloseFlag = true;
      this.$router.push({
        path: "/product/detail",
        query: { productCode: item.productId, orgId: item.orgId }
      });
    },

    scrollHanle(evt) {
    }
  }
};
</script>

<style lang="less">
.page-header {
  width: 100%;
  min-width: 1188px;
  height: 40px;
  background: rgba(69, 69, 69, 1);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  z-index: 102;
  top: 0;
  left: 0;
  .header-in {
    width: 1188px;
    height: 100%;
    margin: 0 auto;
    .logo-box {
      float: left;
      height: 100%;
      // cursor: pointer;
      img {
        width: 56px;
        height: 34px;
        margin-right: 24px;
      }
      span {
        font-size: 12px;
        color: #ccc;
      }

      .change-v {
        height: 40px;
        overflow: hidden;
        margin-left: 24px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        i {
          color: #00aaa6;
        }
        &:hover {
          .swiper-slide {
            i {
              text-decoration: underline;
            }
          }
        }
        div.swiper-slide {
          line-height: 40px;
        }
        // li {
        //   list-style: none;
        //   animation: move 3s linear infinite;
        //   animation-delay: 2s; /*延迟2s在进行滚动*/
        //   width: 100%;
        //   overflow: hidden;
        //   // background: #00aaa6;
        //   height: 40px;
        //   line-height: 40px;
        //   color: #fff;
        // }
        // &:hover {
        //   li {
        //     animation-play-state: paused; /*鼠标悬浮，动画停止*/
        //   }
        // }
        // /* 添加动画 */
        // @keyframes move {
        //   from {
        //     transform: translateY(0);
        //   }
        //   to {
        //     transform: translateY(-40px);
        //   }
        // }
      }
      // .toOld {
      //   margin-left: 24px;
      //   color: #fff;
      //   i {
      //     color: #00aaa6;
      //     cursor: pointer;
      //   }
      // }
    }
    .nav-box {
      float: right;
      height: 40px;
      line-height: 40px;
      background-color: transparent;
      border: none;
      position: relative;
      &.driver-highlighted-element {
        .ant-menu-item {
          color: #262626;
          > i {
            color: #262626;
          }
        }
      }
      .user-slt {
        position: relative;
        padding: 0 8px !important;
        &:hover {
          .icon-arrow-right-small-copy {
            color: #262626;
          }
          .user-slt-inf {
            display: block;
          }
        }
        .user-slt-inf {
          display: none;
          position: absolute;
          top: 40px;
          right: 0px;
          // left: -22px;
          background: #ffffff;
          width: 332px;
          // height: 162px;
          height: auto;
          z-index: 101;
          box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: default;
          ul {
            padding: 16px;
            float: left;
            li {
              text-align: left;
              // height: 18px;
              height: auto;
              color: #262626;
              font-size: 12px;
              line-height: 18px;
              clear:both;
              overflow: hidden;
              & + li {
                margin-top: 10px;
              }
              p {
                display: block;
                width: 60px;
                float: left;
              }
              > div {
                float: left;
                // width: 250px;
                width: calc(100% - 80px);
                max-height: 52px;
                overflow: hidden;
                white-space: pre-wrap;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
              }
              > .exitLogin{
                color: #777;
                cursor: pointer;
                font-size: 12px;
                display: flex;
                align-items: center;
                background: none;
                &:hover{
                  color: #00aaa6
                }
                .iconfont{
                  font-size: 12px;
                  margin-right:3px;
                  margin-top: 2px;
                }
              }
            }
          }
        }

        span.img {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          overflow: hidden;
          float: left;
          margin-top: 8px;
          margin-right: 8px;
          color: rgba(204, 204, 204, 1);

          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon-arrow-right-small-copy {
          font-size: 12px;
          transform: rotate(90deg);
          color: #ccc;
        }
      }

      .sjb-item {
        position: relative;
        padding: 0 16px !important;
        &:hover {
          color: #00aaa6 !important;
          > i {
            color: #00aaa6;
          }
          .sjb-box {
            display: block;
          }
        }
        > i {
          font-size: 14px;
          margin-right: 2px;
        }
        .sjb-box {
          display: none;
          width: 244px;
          height: 249px;
          position: absolute;
          top: 40px;
          right: 0px;
          background: #ffffff;
          z-index: 101;
          box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          cursor: default;
          // <a-menu-item class="sjb-box">
          //   <div class="code-box">
          //     <div class="item-left">
          //       <img src="" alt="">
          //     </div>
          //     <div class="item-right">
          //       <h3>信天翁微信小程序</h3>
          //       <div class="icon-box">
          //         <i class="iconfont icon-shouye-gouwuche shoppingTrolley-gouwuche"></i>
          //         <span>微信</span>
          //       </div>
          //     </div>
          //   </div>
          // </a-menu-item>
          .code-box {
            height: 124px;
            padding: 16px;
            padding-right: 0;
            .item-left {
              width: 84px;
              height: 84px;
              background: #ffffff;
              border-radius: 4px;
              border: 1px solid #eeeeee;
              padding: 3px;
              -webkit-box-sizing: content-box;
              float: left;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .item-right {
              float: left;
              text-align: left;
              margin-left: 11px;
              h3 {
                font-size: 14px;
                color: #262626;
                height: 14px;
                line-height: 14px;
                margin-top: 6px;
              }
              h4 {
                height: 12px;
                font-size: 12px;
                color: #00aaa6;
                line-height: 12px;
                margin-top: 8px;
              }
              .icon-box {
                margin-top: 12px;
                float: left;
                .icon-item {
                  display: flex;
                  flex-direction: column;
                  // justify-content: space-between;
                  align-items: center;
                  float: left;
                  margin-right: 16px;
                  > i {
                    width: 22px;
                    height: 18px;
                    // line-height: 18px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    color: #777;
                  }
                  > span {
                    height: 12px;
                    font-size: 12px;
                    // font-family: MicrosoftYaHei;
                    color: #777777;
                    line-height: 12px;
                    margin-top: 4px;
                  }
                }
                &:last-child {
                  .icon-item {
                    height: 38px;
                  }
                  .icon-item:first-child {
                    > i {
                      height: 22px;
                    }
                  }
                  .icon-item:last-child {
                    > i {
                      height: 20px;
                      margin-top: 4px;
                    }
                  }
                }
              }
            }
            + .code-box {
              border-top: 1px solid #eee;
            }
          }
        }
      }
      .wzdh-item {
        // position: unset !important;
        padding: 0 16px !important;
        &:hover {
          color: #00aaa6 !important;
          > i {
            color: #00aaa6 !important;
          }
          .wzdh-box {
            display: block;
          }
        }
        &.close {
          .wzdh-box {
            display: none;
          }
        }
        > i {
          font-size: 14px;
          margin-right: 2px;
        }
        .wzdh-box {
          width: 1188px;
          display: none;
          position: absolute;
          top: 40px;
          right: 0px;
          background: #ffffff;
          z-index: 101;
          box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding-bottom: 20px;
          cursor: default;
          // display: flex;
          // justify-content: space-between;
          .list {
            // padding: 16px 0px 26px;
            width: calc(50% - 30px);
            margin-left: 30px;
            text-align: left;
            float: left;
            > div {
              height: auto;
              h1 {
                color: #00aaa6;
                line-height: 56px;
                font-weight: 500;
                font-size: 20px;
                text-indent: 8px;
                &.handler {
                  cursor: pointer;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
              .c-box {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
              }
              ol {
                margin-bottom: 20px;
                > li {
                  width: 170px;
                  // height: 300px;
                  margin-left: 8px;
                  // background-color: #ccc;
                  // float: left;
                  // height: 180px;

                  h2 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 38px;
                    line-height: 38px;
                    font-size: 16px;
                    color: #262626;
                    border-bottom: 1px solid #eee;
                    width: 170px;
                    // cursor: pointer;
                  }
                  > ul {
                    padding-top: 10px;
                    li {
                      font-size: 14px;
                      width: 100%;
                      height: 30px;
                      line-height: 30px;
                      text-indent: 4px;
                      color: #262626;
                      cursor: pointer;
                      &:hover {
                        background: #f7f7f7;
                        color: #00aaa6;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .ant-menu-item-selected {
        // color: #fff !important;
        // background: linear-gradient(
        //   225deg,
        //   rgba(0, 170, 166, 1) 0%,
        //   rgba(0, 188, 183, 1) 100%
        // );
      }
      .ant-menu-item,
      .ant-menu-submenu-title {
        padding: 0 16px;
      }

      .login-item.noLogin {
        font-size: 12px;
        background-color: #00aaa6;
        color: #fff !important;
        // padding: 0 22px;
        padding: 0 38px;
      }
      .ant-menu-item,
      .ant-menu-submenu,
      .ant-menu-submenu-title {
        border: none;
        font-size: 12px;
        // color: rgba(204, 204, 204, 1);
        color: #ccc;
        float: left;
        margin: 0;
        top: 0;
        // transition: all 0.15s;
        transition: all 0s;
        > i {
          color: #ccc;
        }
        &.item-more {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: transparent;
            box-sizing: border-box;
            left: 0;
            bottom: 0;
            z-index: 102;
            pointer-events: none;
            // transition: all 0.1s;
          }
        }
        &.shoppingTrolley.zero {
          background: rgba(38, 38, 38, 0.29);
        }
        &.shoppingTrolley {
          color: #fff !important;
          background: #00b7b3;
          i {
            transition: 0.15s;
            color: #fff;
          }
          &.close {
            color: #fff !important;
            background: #00b7b3;
            box-shadow: none !important;
            i {
              transition: 0.15s;
              color: #fff;
            }
            li.active {
              box-shadow: none;
            }
            .commodityList {
              display: none;
            }
          }
        }
        &:hover {
          // color: #000 !important;
          // background: #fff;
          &.login-item.noLogin {
            color: #fff !important;
            background-color: #00b7b3;
          }
          &.item {
            color: #fff !important;
            background: transparent;
          }
          &.item-more {
            color: #262626;
            background: #fff;
            box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.17);
            &::after {
              background-color: #fff;
            }
            &.sjb-item,
            &.wzdh-item,
            &.shoppingTrolley {
              color: #00aaa6 !important;
              background: #fff;
              i {
                transition: 0.15s;
                color: #00aaa6;
              }
            }
          }
        }

        &.ant-menu-item-active,
        &.ant-menu-submenu-active,
        &.ant-menu-item-open,
        .ant-menu-horizontal > .ant-menu-submenu-open,
        // &.ant-menu-item-selected,
        &.ant-menu-submenu-selected {
          color: #000 !important;
          // color: rgba(255, 255, 255, 1) !important;
          // background: linear-gradient(
          //   225deg,
          //   rgba(0, 170, 166, 1) 0%,
          //   rgba(0, 188, 183, 1) 100%
          // );
        }
        .anticon {
          font-size: 16px;
          margin-right: 4px;
        }
      }
      .shoppingTrolley {
        position: relative;
        padding: 0 14px !important;
        .shoppingTrolley-gouwuche {
          font-size: 14px!important;
          margin-right: 2px;
        }
        &:hover {
          box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.17);
          .active {
            display: block;
          }
        }

        .icon-gouwuche-moren {
          font-size: 14px;
          // color: #fff;
          margin-right: 4px;
        }
        .active {
          position: absolute;
          top: 40px;
          right: 0;
          width: 400px;
          // height: 800px;
          background: #ffffff;
          z-index: 101;
          display: none;
          box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          overflow: hidden;

          .commodityEmpty {
            width: 400px;
            height: 200px;
            background: #fff;
            position: relative;
            div {
              width: 86px;
              height: 86px;
              position: absolute;
              top: 38px;
              left: 156px;
              border-radius: 50%;
              // background: #aaa;
              background: url("../../assets/common/carEmpty.png") no-repeat
                center;
              i {
                // display: block;
                // width: 84px;
                // height: 84px;
                // background: #666;
                font-size: 88px;
                // color: #eee;
                color: #f7f7f7;
                padding-top: 23px;
                // border-radius: 50%;
              }
            }
            p {
              padding-top: 142px;
              font-size: 14px;
              color: #aaaaaa;
              line-height: 20px;
            }
          }
          .commodityList {
            width: 400px;
            overflow: hidden;
            // background: skyblue;
            .commodityBox {
              width: 400px;
              max-height: 480px;
              // overflow: auto;
              // width: 368px;
              // height: 96px;
              /*滚动条样式*/
              &::-webkit-scrollbar {
                width: 8px;
                /*height: 4px;*/
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                // background: rgba(0, 0, 0, 0.2);
                background: #ccc;
              }
              &::-webkit-scrollbar-track {
                border-radius: 10px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                // border-radius: 0;
                // background: rgba(0, 0, 0, 0.1);
                background: #eeeeee;
              }
              .commodity {
                // width: 368px;
                width: 352px;
                height: 88px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                // cursor: default;
                position: relative;
                & + .commodity {
                  border-top: 1px solid #eeeeee;
                }
                .count {
                  height: 16px;
                  font-size: 12px;
                  color: #262626 !important;
                  line-height: 16px;
                  position: absolute;
                  right: 32px;
                  bottom: 16px;
                  i {
                    color: #262626 !important;
                  }
                }
                .guanbigwc {
                  display: none;
                  position: absolute;
                  right: -6px;
                  bottom: 38px;
                  width: 16px;
                  height: 16px;
                  border-radius: 8px;
                  color: #aaa;
                  cursor: pointer;
                  line-height: 16px;
                  text-align: center;
                  // display: block;
                  justify-content: center;
                  align-items: center;
                  animation-duration: 0.6s;
                  i {
                    color: #aaa;
                    float: left;
                    font-size: 16px;
                    text-indent: 0.5px;
                  }
                  &:hover {
                    background: #00aaa6;
                    i {
                      color: #fff;
                    }
                  }
                }
                &:hover {
                  .commodityPar-left {
                    p.info {
                      color: #00aaa6 !important;
                    }
                  }
                  .guanbigwc {
                    display: flex;
                    // visibility: visible !important;
                  }
                }
                .img-box {
                  width: 64px;
                  height: 64px;
                  // padding: 16px 0;
                  float: left;
                  img {
                    width: 100%;
                    height: 100%;
                    // width: 64px;
                    // height: 43px;
                    // margin: 11px 0;
                  }
                }
                .commodityPar {
                  // width: 300px;
                  height: 88px;
                  float: left;
                  display: flex;
                  // justify-content: space-between;
                  align-items: center;
                  position: relative;

                  .commodityPar-left {
                    width: 242px;
                    margin-left: 12px;
                    float: left;
                    font-size: 12px;
                    font-weight: 400;
                    color: #262626;
                    line-height: 20px;
                    text-align: left;
                    transition: all 0.15s;
                    p.info {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                    p.price {
                      font-size: 12px;
                      height: 16px;
                      color: #262626;
                      line-height: 16px;
                      margin-top: 10px;
                    }
                  }
                  // .commodityPar-right {
                  //   float: right;
                  //   // margin-right: 12px;
                  //   display: flex;
                  //   justify-content: space-between;
                  //   align-items: center;
                  //   .content-in {
                  //     margin-right: 12px;
                  //     .top {
                  //       height: 20px;
                  //       line-height: 20px;
                  //       text-align: right;
                  //       span {
                  //         width: 39px;
                  //         height: 20px;
                  //         font-size: 12px;
                  //         color: #262626;
                  //         line-height: 20px;
                  //         // margin-top: 40px;
                  //         &.price2 {
                  //           color: #aaaaaa;
                  //           margin-left: 12px;
                  //           text-decoration: line-through;
                  //         }
                  //       }
                  //     }
                  //     .bottom {
                  //       height: 20px;
                  //       line-height: 20px;
                  //       text-align: right;
                  //       span {
                  //         // display: flex;
                  //         // justify-content: center;
                  //         line-height: 20px;
                  //         color: #777;
                  //         i {
                  //           padding-left: 2px;
                  //           color: #777;
                  //           font-size: 14px;
                  //         }
                  //       }
                  //     }
                  //   }

                  //   i.guanbigwc {
                  //     display: inline-block;
                  //     width: 16px;
                  //     height: 16px;
                  //     text-align: center;
                  //     line-height: 16px;
                  //     font-size: 14px;
                  //     color: #777;
                  //     transition: transform 0.3s;
                  //     visibility: hidden;
                  //     cursor: pointer;
                  //     &:hover {
                  //       color: #00aaa6;
                  //       transform: rotate(90deg);
                  //     }
                  //   }
                  // }
                }
              }
            }
            .account {
              width: 400px;
              height: 74px;
              background: #f7f7f7;
              position: relative;
              cursor: default;
              span.count-num {
                width: 56px;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #262626;
                line-height: 17px;
                position: absolute;
                top: 17px;
                left: 16px;
              }
              span.price {
                // width: 48px;
                height: 28px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #d9161c;
                line-height: 28px;
                position: absolute;
                top: 34px;
                left: 16px;
                text-align: left;
                i {
                  font-weight: 600;
                  color: #d9161c;
                  font-size: 20px;
                  margin-right: 2px;
                }
              }
              .trolley_btn {
                position: absolute;
                top: 18px;
                right: 16px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.ant-notification {
  // display: none !important;
  height: 0;
}
</style>
