<!--
 * @Author: sqk
 * @Date: 2020-08-10 15:39:59
 * @LastEditTime: 2021-06-15 16:50:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\layout\TopDownLayout\Menu.vue
-->

<template>
  <div class="page-menu">
    <div class="menu-in">
      <router-link :to="{
                  path:'/index'
              }">
        <div class="logo-box" title="首页">
          <!-- <img src="../../assets/common/logo.png" alt /> -->
        </div>
      </router-link>
      <a-menu class="menu-box" id="menu" v-model="menuModel" mode="horizontal">
        <template v-for="(item,index) in menuList">
          <a-menu-item
            :id="item.id == '14175010064' ? 'productClass' : ''"
            :key="item.id"
            v-if="item.menuType == 1"
            @mouseenter="popTypeShow(item,true,index,$event)"
            @mouseleave="popTypeShow(item,false,index,$event)"
            @click="menuItemClick(item)"
          >{{item.sourceName}}</a-menu-item>
        </template>
      </a-menu>
      <div v-if="userInfo && (userInfo.account.loginSystem.indexOf('14168810880') != '-1' || userInfo.account.loginSystem.indexOf('14168810879') != '-1')" class="search-box">
        <div v-if="showActiveBox" class="ant-select-box">
          <a-select
            dropdownClassName="index-search"
            show-search
            label-in-value
            :value="searchVal"
            placeholder="请输入产品型号/品类"
            dropdown-class-name="search-list"
            style="width: 100%"
            :filter-option="false"
            :show-arrow="false"
            :defaultActiveFirstOption="true"
            @focus="focusFun"
            @blur="blurFun"
            @search="handleSearch"
            @select="handleSelect"
            @inputKeydown="inputKeydown"
          >
            <!-- :not-found-content="searchLoading ? undefined : ''" -->
            <a-spin v-if="searchLoading" slot="notFoundContent" size="small" />
            <a-select-option
              v-for="(item,index) in searchDftList"
              :key="index.toString() + ':' + item"
            >{{ item }}</a-select-option>
          </a-select>
          <i class="iconfont icon-shouye-sousuo search-icon" @click="searchFun"></i>
        </div>
      </div>

      <!-- <div
        class="popType1 animated"
        v-show="isPopShow && ispopType1"
        :class="[popType1ShowFlag,{'is-child-show' : popType1ChildShowFlag && popType1ShowFlag == 'fadeInLeft'},{'leave':!popType1ChildShowFlag}]"
        @mouseenter="popType1Show(true)"
        @mouseleave="popType1Show(false)"
      > -->
      <div
        class="popType1 animated"
        v-show="isPopShow && ispopType1"
        :class="[popType1ShowFlag]"
        @mouseenter="popType1Show(true)"
        @mouseleave="popType1Show(false)"
      >
        <ul :class="{'whiteBarShow':popType1ChildShowFlag}">
          <li
            v-show="index < 10"
            v-for="(item,index) in productList"
            :key="item.menu1.id"
            :class="item.menu1.active ? 'active' : 'not-active'"
            @mouseenter="popType1ChildShow(true,index)"
            @mouseleave="popType1ChildShow(false,'',true)"
            @click="productSearch(item)"
          >
            <span>{{item.menu1.tagName}}</span>
            <i class="iconfont icon-shouye-gonggao-youdianji"></i>
          </li>
        </ul>
        <!-- <span @mouseenter="popType1ChildShow">试一试</span> -->
      </div>
      <!-- <div
        class="popType1Child animated"
        v-show="isPopShow"
        :class="[popType1ChildShowFlag ? 'fadeIn' : 'fadeOut',isMoreFun(productItemList.length)]"
        @mouseenter="popType1AllShow(true)"
        @mouseleave="popType1AllShow(false)"
      >-->
      <div
        class="popType1Child animated"
        v-show="isPopShow && popType1ChildShowFlag"
        :class="[isMoreFun(productItemList.menu2.length)]"
        @mouseenter="popType1AllShow(true)"
        @mouseleave="popType1AllShow(false)"
      >
        <div class="popType1Child-box">
          <ul class="clearfix">
            <li
              v-for="item in productItemList.menu2"
              :key="'menu2' + item.id"
              @click="productItemSearch(item,productItemList.menu1)"
            >
              <img :src="item.fullPic" />
              <span>{{item.tagName}}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- <transition name="fadeUp"> -->
      <div
        class="popType2"
        v-show="userInfo && isPopShow && !ispopType1 && popType2List.length > 0"
        :class="[popType2ShowFlag,{'more-style' : isPopType2More},{'animated' :!isPopType2More}]"
        @mouseenter="popType2Show(true)"
        :style="popType2Distance"
        @mouseleave="popType2Show(false)"
      >
        <div class="popType2-box">
          <ul>
            <li v-for="item in popType2List" :key="'menu3' + item.id" v-show="item.menuType == 1">
              <h2 @click="menuItemClick(item)">{{item.sourceName}}</h2>
              <ol>
                <li
                  v-for="itemLv3 in item.subMenuList"
                  v-show="item.menuType == 1 && itemLv3.sourceName!== '搜索框' && itemLv3.sourceName!== '购物车' && itemLv3.sourceName!== '收藏夹'"
                  :key="itemLv3.id"
                  @click="menuItemClick(itemLv3)"
                >{{itemLv3.sourceName}}</li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <!--      :class="[popType3ShowFlag]"-->
      <!--      :class= "[popType3ShowFlag],{'usedList.length>4'?'usedLLLL':'usedLLLL4'}"-->
      <div
        class="popType2 animated "
        v-show="ispopType2 && userInfo && usedList && usedList.length>0"
        :class="[popType3ShowFlag]"
        :style="popType3Distance"
        @mouseenter="popType3Show(true)"
        @mouseleave="popType3Show(false)"
      >
        <div :class="usedList&&usedList.length>4?'popType2-box usedListType':'popType2-box usedListType4' ">
          <ul class="usedUl">
            <li class="usedli">
              <ol class=" usedol">
                <li
                  class="lili"
                  v-for="(item,index) in usedList"
                  :key="index"
                  @click="usedItemClick(item)"
                  v-show="index <= 7"
                >{{item.customerAccountSourceName}}</li>
              </ol>
            </li>
          </ul>
        </div>
        <!--          <ul >-->
        <!--            <li-->
        <!--              v-show="index < 10"-->
        <!--              v-for="(item,index) in usedList"-->
        <!--              :key="index"-->
        <!--              :class="item.active ? 'active' : 'not-active'"-->
        <!--              @mouseenter="popType3ChildShow(true,index)"-->
        <!--              @mouseleave="popType3ChildShow(false,'',true)"-->
        <!--              @click="menuItemClick(item)"-->
        <!--            >-->
        <!--              <span>{{item.customerAccountSourceName}}</span>-->
        <!--            </li>-->
        <!--          </ul>-->


      </div>

      <!-- <div
        class="popType2 more-style"
        v-show="isPopShow && isPopType2ListMoreShow"
        :class="[popType2ShowFlag ? 'fadeInUp' : 'fadeOut']"
        @mouseenter="isPopType2ListMoreShow = true"
        :style="popType2Distance"
        @mouseleave="isPopType2ListMoreShow = false"
      >
        <ul>
          <li v-for="item in popType2List" :key="'menu4' + item.id">
            <h2 @click="menuItemClick(item)">{{item.sourceName}}</h2>
            <ol>
              <li
                v-for="itemLv3 in item.subMenuList"
                :key="itemLv3.id"
                @click="menuItemClick(itemLv3)"
              >{{itemLv3.sourceName}}</li>
            </ol>
          </li>
        </ul>
      </div>-->
      <!-- </transition> -->
    </div>
    <LoginShop :visible.sync="o2oVisible" />
    <HisenseAgreement
      :visible.sync="o2oAgreementVisible"
      :cacheSign="cacheSign"
    />
  </div>
</template>

<script>
// import menuList2 from "./MenuList";
// 导入api接口
import {
  menuList,
  custMenuTag,
  productSearch,
  wordSearch,
  addBrowsing,
  getshopStore,
  getshopLogin,
  useListA, wordSearchNew,
} from "@/utils/api.js";
import store from "@/store";
import { env } from "@/common/constant";
import LoginShop from "@/views/LoginShop/LoginShop.vue";
import Util from "@/utils/utils";
export default {
  components: { LoginShop },
  data() {
    // this.searchDftListAll = [
    //   {
    //     key: "高清电视",
    //     label: "高清电视"
    //   },
    //   {
    //     key: "空调",
    //     label: "空调"
    //   },
    //   {
    //     key: "冰箱",
    //     label: "冰箱"
    //   },
    //   {
    //     key: "洗衣机",
    //     label: "洗衣机"
    //   },
    //   {
    //     key: "冰柜",
    //     label: "冰柜"
    //   },
    //   {
    //     key: "手机",
    //     label: "手机"
    //   }
    // ];
    this.searchDftListAll = ["电视", "空调", "冰箱", "洗衣机",'厨卫'];
    return {
      searchVal: undefined, //搜索关键字
      searchText: "", //搜索文本
      searchLoading: false, //搜索loadding
      isSearchStart: false, // 是否回到初始输入状态
      searchDftList: this.PLlist,
      PLlist:[],
      searchDftListArr:[],
      isPopShow: false, //初始化 隐藏
      ispopType1: false, //是否展示第一项下拉
      ispopType2:false,// 最近使用是否展示
      popType1ShowFlag: "",
      popType1ChildShowFlag: false,
      popType3ChildShowFlag: false,
      popType2ShowFlag: "",
      popType3ShowFlag:'',
      timer: "", //一级菜单防止误触定时器
      popType1ChildTimer: "", //type1下拉菜单防止误触定时器
      popType2Timer: "", //type2下拉菜单展示定时器
      //产品分类列表
      productList: [],
      //产品列表
      productItemList: { menu1: [], menu2: [] },

      popType2List: [],
      menuModel: [0],

      // sliderActive: "",
      // sliderObj: {
      //   left: 0,
      //   width: 0
      // },
      // leaveListen: "",
      // menuList: menuList
      menuList: [],
      dataList: [], //全部数据
      popType2Distance: "0", //类型菜单位置
      popType3Distance:'0',
      popType1ShowInv: null,
      popType2ShowInv: null,
      //菜单的更多项是否已显示
      isPopType2More: false,
      //菜单的更多项是否显示
      // isPopType2ListMoreShow: false,
      showActiveBox: false,
      scrollSettings: {
        //如果此选项为 true，则当滚动到达边的末尾时，mousewheel 事件将传播到父元素。
        wheelPropagation: false,
        showActiveBox:false
      },
      usedList:[],
      o2oVisible: false, // o2o中心点击后弹窗
      o2oAgreementVisible: false, // 登录海信商城后  如果用户未开户弹出
      cacheSign: null, // 跳转海信商城数据
    };
  },
  watch: {
    $route: function(to) {
      this.menuList.forEach((el, index) => {
        if (el.url == to.path) {
          this.itemClick(false, index);
        }
      });
      this.getUseList()
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    }
  },
  mounted() {
    //获取导航
    if (this.userInfo) {
      this.getMenuList()
      this.getUseList()
    }
    // this.getMenuList();
    //产品分类接口
    this.getCustMenuTag();
 
    // 判断当前活动菜单
    // this.menuList.forEach((el, index) => {
    //   if (el.url == this.$route.path) {
    //     this.itemClick(false, index);
    //   }
    // });
  },
  methods: {
    getUseList() {
      if (!this.userInfo.account.account) return
      useListA({}).then(res => {
        this.usedList = res.data.list
      })
    },
    //判断数据量 赋值class
    isMoreFun(dataLength) {
      let cls = "";
      if (dataLength < 6) {
        cls = "one-line";
      } else if (dataLength < 11) {
        cls = "two-line";
      } else if (dataLength < 16) {
        cls = "three-line";
      } else if (dataLength < 21) {
        cls = "four-line";
      } else {
        cls = "more";
      }
      return cls;
    },
    // 获取菜单列表
    getMenuList() {
      //调用接口
      let params = { pageNo: 1, pageSize: 20,  loginPlant:'WEBNEW'};
      menuList(params)
        .then(res => {
          this.dataList = res.data.list;
          let level0List = [];
          if (res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            // console.log(res);
            //成功返回
            for (const key in res.data.list) {
              if (res.data.list[key].level == 0) {
                //无下单权限 -> 移除产品列表 14168810880海信下单，14168810879渠道下单
                if(!(res.data.list[key].id == '14175010064' && this.userInfo.account.loginSystem.indexOf('14168810880') == '-1' && this.userInfo.account.loginSystem.indexOf('14168810879') == '-1')){
                  level0List.push(res.data.list[key]);
                }
              }
            }
          }
          localStorage.setItem("menuList", JSON.stringify(level0List));
          this.menuList = level0List;
          this.$store.commit("user/SET_MENU_LIST", this.menuList);
          this.$store.commit("user/SET_BTN_LIST", res.data.rightList);
          for(let i = 0;i<this.menuList.length;i++) {
            if(this.menuList[i].sourceName === '个人中心') {
              let twoItem = this.menuList[i];
              for(let a = 0;a<twoItem.subMenuList.length;a++) {
                if(twoItem.subMenuList[a].sourceName === '其他') {
                  let three = twoItem.subMenuList[a]
                  for(let bb = 0;bb<three.subMenuList.length;bb++) {
                    if(three.subMenuList[bb].sourceName === '搜索框'){
                      this.showActiveBox = true
                      break
                    } else {
                      this.showActiveBox = false
                    }
                  }
                  break
                }else {
                  this.showActiveBox = false
                }
              }
            }

          }
          // console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    //获取产品分类列表及产品列表
    getCustMenuTag() {
      // let params = { pageNo: 1, pageSize: 20 };
      custMenuTag()
        .then(res => {
          if (res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            //成功返回
            this.productList = res.data.list;
            // 如果菜单 就push到右侧品类的数组
            this.PLlist = []
            if( this.productList.length > 0){
              this.productList.forEach(item=>{
                this.PLlist.push(item.menu1.tagName)
              })
              this.searchDftList = this.PLlist;

            }
          }
          // console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    //判断菜单id 展示不同下拉菜单
    popTypeShow(item, flag, idx, e) {
      this.isPopShow = true;
      // console.log(idx);
      if (item.id == "14175010064") {
        //产品

        // this.popType1Show(flag);

        if (flag) {
          for (let item of this.productList) {
            item.menu1.active = false;
          }

          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.ispopType1 = true;
            this.popType1Show(true);
            clearTimeout(this.timer);
          }, 200);
        } else {
          clearTimeout(this.timer);
          // this.ispopType1 = false;
          this.popType1Show(false);
          this.popType3Show(false)
          // this.popType1Show(false);
          // this.timer = setTimeout(() => {
          //   // clearTimeout(this.timer);
          //   this.popType1Show(false);
          //   clearTimeout(this.timer);
          // }, 1000);
        }
      } else if (item.id == "14922839385") {
        // 最近使用
        if (flag) {
          for (let item of this.usedList) {
            item.active = false;
          }
          let navWidth = 0;
          //定义样式对象
          let style = {};
          // 96 * (idx + 0.5)
          for (const index in this.menuList) {
            navWidth += this.menuList[index].sourceName.length * 16 + 32;
            if (index == idx) {
              navWidth -=
                (this.menuList[index].sourceName.length * 16 + 32) / 2;
              break;
            }
          }
          // console.log(navWidth);
          this.popType3Distance =
            92 +
            navWidth -
            (80 +
              180 * item.subMenuList.length +
              40 * (item.subMenuList.length - 1)) /
            2;
          if (this.popType3Distance > 0 || this.popType3Distance == 0) {
            if (
              this.popType3Distance +
              (80 +
                180 * item.subMenuList.length +
                40 * (item.subMenuList.length - 1)) >
              1188
            ) {
              style.left = "auto";
              style.right = "0px";
            } else {
              // 判断距离 如果使用列表返回数据为4个以上 就距离左边原来的距离-180 小于4个或者等于4 -60
              // style.left = (this.popType3Distance -180) + "px";
              if(this.usedList.length > 4) {
                style.left = (this.popType3Distance -180) + "px";
              } else {
                style.left = (this.popType3Distance -60) + "px";
              }
            }
          } else if (this.popType3Distance < 0) {
            style.left = "0px";
          }
          this.popType3Distance = style;
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.ispopType2 = true;
            this.popType3Show(true);
            clearTimeout(this.timer);
          }, 200);
        } else {
          clearTimeout(this.timer);
          // this.ispopType1 = false;
          this.popType1Show(false);
          this.popType3Show(false)
        }
      } else if (item.subMenuList && item.subMenuList.length > 0) {
        //其他subMenuList
        this.ispopType1 = false;

        if (flag) {
          if (e.domEvent.target.offsetLeft != 0) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.isPopType2More = false;
              //菜单的更多项是否已显示
              //类型菜单位置 左侧
              // li宽180 ul边距80 li间距40 nav总宽度(最后一项取一半)navWidth  内容区总宽1188
              // 总公式= nav总宽 - （ul边距 + li总宽 + li间距）/2
              // debugger  102 logo宽度92 + ul边距

              //字数*fontsize16 + 边距32
              let navWidth = 0;
              //定义样式对象
              let style = {};
              // 96 * (idx + 0.5)
              for (const index in this.menuList) {
                navWidth += this.menuList[index].sourceName.length * 16 + 32;
                if (index == idx) {
                  navWidth -=
                    (this.menuList[index].sourceName.length * 16 + 32) / 2;
                  break;
                }
              }
              // console.log(navWidth);
              this.popType2Distance =
                92 +
                navWidth -
                (80 +
                  180 * item.subMenuList.length +
                  40 * (item.subMenuList.length - 1)) /
                2;
              if (this.popType2Distance > 0 || this.popType2Distance == 0) {
                if (
                  this.popType2Distance +
                  (80 +
                    180 * item.subMenuList.length +
                    40 * (item.subMenuList.length - 1)) >
                  1188
                ) {
                  style.left = "auto";
                  style.right = "0px";
                } else {
                  style.left = this.popType2Distance + "px";
                }
              } else if (this.popType2Distance < 0) {
                style.left = "0px";
              }
              this.popType2Distance = style;

              // if(this.popType2List && this.popType2List.length > 0){
              //   this.popType2Show(true);
              // }

              this.popType2List = item.subMenuList;
              this.popType2Show(true);

              clearTimeout(this.timer);
            }, 200);

            //类型菜单数据
            // for (const key in item.subMenuList) {
            //   this.popType2List.push(item.subMenuList[key]);
            // }
            // this.popType2List = item.subMenuList;
          } else {
            this.isPopType2More = true;
            // this.isPopType2ListMoreShow = flag;
            clearTimeout(this.popType2Timer);
            this.popType2Show(true);
            let style = {};
            style.top = "81px";
            style.left = "auto";
            style.right = "362px";
            this.popType2Distance = style;
            this.popType2List = item.subMenuList;
            // if (flag) {
            //   this.popType2Show(true);
            //   let style = {};
            //   style.top = "81px";
            //   style.left = "auto";
            //   style.right = "362px";
            //   this.popType2Distance = style;
            //   this.popType2List = item.subMenuList;
            // } else {
            //   this.popType2Timer = setTimeout(() => {
            //     this.popType2Show(false);
            //   }, 600);
            // }
          }
        } else {
          if (e.domEvent.target.offsetLeft != 0) {
            // this.timer = setTimeout(() => {
            //   // clearTimeout(this.timer);
            //   this.popType2Show(false);
            // }, 300);
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              // clearTimeout(this.timer);
              this.popType2Show(false);
              clearTimeout(this.timer);
            }, 200);

            // this.popType2Show(false);
          } else {
            this.popType2Timer = setTimeout(() => {
              this.popType2Show(false);
            }, 600);
          }
        }
      } else {
        //单项
        clearTimeout(this.popType2Timer);
        this.popType2Show(false);
      }
    },
    //产品分类下拉展示隐藏
    popType1Show(flag) {
      // console.log(flag);
      if (flag) {
        this.popType1ShowFlag = "fadeInLeft";
      } else {
        this.popType1ShowFlag = "fadeOutLeft";
      }
      this.popType2List = [];
      // this.popType1ShowFlag = flag;
      // this.popType2ShowFlag = "fadeOut";
      // for (let item of this.productList) {
      //   item.active = false;
      // }
    },
    // 最近使用下拉展示隐藏
    popType3Show(flag) {
      // console.log(flag);
      if (flag) {
        this.popType3ShowFlag = "fadeInLeft";
      } else {
        this.popType3ShowFlag = "fadeOutLeft";
      }
      this.popType2List = [];
      // this.popType1ShowFlag = flag;
      // this.popType2ShowFlag = "fadeOut";
      // for (let item of this.productList) {
      //   item.active = false;
      // }
    },
    //其他下拉展示隐藏
    // popType2Show(flag) {
    //   this.popType1ShowFlag = false;
    //   this.popType1ChildShowFlag = false;
    //   if (flag == true) {
    //     if (this.popType2ShowInv) {
    //       clearTimeout(this.popType2ShowInv);
    //     }
    //     this.popType2ShowFlag = true;
    //   } else {
    //     this.popType2ShowInv = setTimeout(() => {
    //       this.popType2ShowFlag = false;
    //     }, 50);
    //   }
    //   // this.popType2ShowFlag = flag;
    // },
    popType2Show(flag) {
      // this.popType1ShowFlag = "fadeOutLeft";
      // this.popType1ChildShowFlag = false;
      if (flag) {
        clearTimeout(this.timer);
        clearTimeout(this.popType2Timer);
        this.popType2ShowFlag = "fadeInUp";
      } else {
        this.popType2ShowFlag = "fadeOut";
      }
      // this.popType2ShowFlag = flag;
    },
    //产品分类下级item鼠标浮动-》产品列表展示隐藏
    popType1ChildShow(flag, idx, isPop1ItemLeave) {
      // console.log(flag);clearTimeout(this.timer);
      // this.productItemList = setTimeout(() => {

      // this.popType1ShowFlag = flag;
      this.popType1ChildShowFlag = flag;
      if (flag) {
        //防止误触
        clearTimeout(this.popType1ChildTimer);
        this.popType1ShowFlag = "fadeInLeft";
        this.popType1ChildTimer = setTimeout(() => {
          //产品分类下级item鼠标浮动
          if (flag && typeof idx === "number") {
            for (let item of this.productList) {
              item.menu1.active = false;
            }
            this.productList[idx].menu1.active = true;
            this.productItemList = this.productList[idx];
          }
        }, 50);
      } else {
        clearTimeout(this.popType1ChildTimer);
        this.popType1ShowFlag = "fadeOutLeft";
      }
      if (isPop1ItemLeave) {
        this.popType1ShowFlag = true;
      }
    },
    // 最近使用item 鼠标悬浮
    popType3ChildShow(flag, idx, isPop3ItemLeave) {

      this.popType3ChildShowFlag = flag;
      if (flag) {
        //防止误触
        clearTimeout(this.popType1ChildTimer);
        this.popType3ShowFlag = "fadeInLeft";
        this.popType3ChildTimer = setTimeout(() => {
          //产品分类下级item鼠标浮动
          if (flag && typeof idx === "number") {
            for (let item of this.productList) {
              item.menu1.active = false;
            }
            this.productList[idx].menu1.active = true;
            this.productItemList = this.productList[idx];
          }
        }, 50);
      } else {
        clearTimeout(this.popType1ChildTimer);
        this.popType3ShowFlag = "fadeOutLeft";
      }
      if (isPop3ItemLeave) {
        this.popType1ShowFlag = true;
      }
    },
    //产品分类二级菜单浮动
    popType1AllShow(flag) {
      // this.popType1ShowFlag = flag;
      if (flag) {
        this.popType1ShowFlag = "fadeInLeft";
      } else {
        this.popType1ShowFlag = "fadeOutLeft";
      }
      this.popType1ChildShowFlag = flag;
      //鼠标浮动移出时取消下拉中全部动画效果
      // if (!flag) {
      //   for (let item of this.productList) {
      //     item.menu1.active = false;
      //   }
      // }
    },
    usedItemClick(item) {
      // console.log('item' ,item)
      let data = {
        menuId:item.customerAccountSourceId,
        clientPlatform:'pc'
      }
      addBrowsing(data).then(res => {
        // console.log('res',res);
      })
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', item.vuePath?item.vuePath:item.url]);
        window._paq.push(['setDocumentTitle', item.sourceName]);
        window._paq.push(['trackPageView']);
      }
      if ( item.customerAccountSourceUrl !=null) {
        this.$router.push(item.customerAccountSourceUrl);
        this.popType3ShowFlag = "fadeOut";
      }

    },
    // 检查是否登陆过  非管理员账户使用
    chekcIsLogin(obj, id){
      if(obj.extra && obj.extra.loggedInAccounts){
        const arr = JSON.parse(obj.extra.loggedInAccounts);
        if(arr && arr.findIndex(item => item.logged == id) > -1){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    //非菜单项（链接项）点击跳转
    menuItemClick(item) {
      if((item.level==0 && (item.sourceName =='商品'  || item.sourceName =='海信学堂') || item.level == 2)){
        let urlName = ''
        let url = ''
        if(item.sourceName&&(item.vuePath||item.url)) {
          urlName = item.sourceName
          url = (item.vuePath || item.url)
        }

        if(item.sourceName=='O2O中心' && !item.vuePath && !item.url) {
          urlName = 'O2O中心'
          url = 'O2O'
        }

        // 判断当前点击菜单是否有菜单权限
        const menuList = JSON.parse(localStorage.getItem("menuList") || "[]");
        let flag = Util.isHas(urlName, menuList);
        // 集成wa切换路由的时候调用
        if (typeof window._paq !== 'undefined' && flag) {
          window._paq.push(['setCustomUrl', url]);
          window._paq.push(['setDocumentTitle',urlName]);
          window._paq.push(['trackPageView']);
        }
      }

      
      // 最近使用记录
      addBrowsing({
        menuId:item.id,
        clientPlatform:'pc'
      }).then(res => {})


      // if (item.id == "14175010064" || item.subMenuList.length > 0) {
      if (item.id == "14175010064") {
        return;
      } else {
        // this.popType2ShowFlag = "fadeOut";
        //点击防止退回
        if(item.subMenuList && item.subMenuList.length > 0 && item.subMenuList[0].menuType == 1){
          return
        }

      }
      // debugger
      if(item.id == 15705815198){
        this.$emit("changeLoading", true);
        getshopStore(this.$store.state.user.userInfo.customer.id).then(result => {
          this.$emit("changeLoading", false);
          if(result.data.length == 0){
            return this.$message.warning("您的门店暂未开通海信商城账号，请联系营销中心客户发展部同事进行统一开通");
          }
          const type = this.$store.state.user.userInfo.account.type;
          const account = this.$store.state.user.userInfo.account.account;
          if(result.data.length == 1 && (type == 'main' || (type != 'main' && this.chekcIsLogin(result.data[0], account)) )){
            // 跳转
            getshopLogin(this.$store.state.user.userInfo.customer.id, result.data[0].cisId, account).then(_result => {
              this.$emit("changeLoading", false);
              if(result.data[0].isOpenAccountSuccess == "1" || result.data[0].auditStatus == "1"){
                window.open(`${env == "dev" || env == "test" || env == "uat" ? 'https://test.shop.hisense.com' : 'https://mall.hisense.com'}/xtwLogout?cacheSign=${_result.data.extra.accessToken}&type=${this.$store.state.user.userInfo.account.type}&ciscustomerid=${this.$store.state.user.userInfo.customer.id}`);
              }else{
                this.cacheSign = _result.data.extra.accessToken;
                this.o2oAgreementVisible = true;
              }
            }).catch(() => this.$emit("changeLoading", false))
          }else{
            // 弹窗
            this.$emit("changeLoading", false);
            this.o2oVisible = true;
          }
        }).catch(() => this.$emit("changeLoading", false));
      }else if (item.targetType === "vue" && item.vuePath !=null) {
        this.$router.push(item.vuePath);
        this.popType2ShowFlag = "fadeOut";
      } else if (item.targetType === "blank" && item.url) {
        window.open(item.url, "_blank");
        this.popType2ShowFlag = "fadeOut";
      }
    },

    //产品搜索
    productSearch(item) {
      if (typeof window._paq !== 'undefined' ) {
        window._paq.push(['setCustomUrl', '/product']);
        window._paq.push(['setDocumentTitle','商品']);
        window._paq.push(['trackPageView']);
      }
      //store 传入 默认筛选项数组
      // let obj = {};
      // for (const itemM1 of item.menu1.filter.split(',')) {
      //   console.log(itemM1)
      //   obj[itemM1.split(':')[0]] = itemM1.split(':')[1]
      // }
      // this.$store.commit("filtrate/SET_PRODUCT_DFT_LIST", obj);

      //catalogId->查询列表/筛选使用 filter->筛选使用 tagNameL1，tagNameL2->面包屑使用
      if(this.userInfo){
        if (item.menu1.targetURL) {
          this.$router.push({
            path: item.menu1.targetURL,
            query: {
              filter: item.menu1.filter,
              customName: item.menu1.customName,
              condition: item.menu1.customCondition
            }
          });
        } else {
          this.$router.push({
            path: "/product",
            query: {
              catalogId: item.menu1.catagoryId,
              filter: item.menu1.filter,
              tagNameL1: item.menu1.tagName
            }
          });
        }
      }else{
        this.$router.push({
          path: '/login',
        });
      }


      this.popType1ShowFlag = "fadeOutLeft";
      this.popType1ChildShowFlag = false;
    },

    //产品item搜索
    productItemSearch(item, menu1) {
      if(!this.userInfo){
        this.$router.push({
          path: '/login',
        });
        return
      }

      if (item.targetURL) {
        this.$router.push({
          path: item.targetURL,
          query: {
            filter: item.filter,
            customName: item.customName,
            condition: item.customCondition
          }
        });
      } else {
        this.$router.push({
          path: "/product",
          query: {
            catalogId: item.catagoryId,
            filter: item.filter,
            tagNameL1: menu1.tagName,
            tagNameL2: item.tagName
          }
        });
      }

      this.popType1ShowFlag = "fadeOutLeft";
      this.popType1ChildShowFlag = false;
    },
    //获得焦点时回调
    focusFun() {
      // this.searchText = "";
      this.searchVal = '';
    },
    blurFun(value) {
      // console.log(11564);
      // console.log(value);
    },
    //文本框值变化时回调
    handleSearch(value) {
      // 搜索优化 输入大于3个字才调接口
      if(value.length<=2){
        return
      }
      // console.log("handleSearch", value);
      this.searchText = value;
      if(!this.userInfo){
        this.$router.push({
          path: '/login',
        });
        return
      }
      if (value) {
        this.isSearchStart = false;
        this.searchDftList = [];
        this.getProductSearch(value);
      } else {
        this.isSearchStart = true;
        this.searchDftList = this.PLlist;


      }
    },
    //被选中时调用，参数为选中项的 value (或 key) 值
    handleSelect(value) {
      this.searchVal = value;
      let catalogId = ''
      let filter = ''
      let tagNameL1 = ''
      // 点击跳转页面 获取对应的参数
      if (this.searchDftList == this.PLlist) {
        if(this.productList.length > 0) {
          for(let i =0;i<this.productList.length;i++) {
            if(this.productList[i].menu1.tagName == value.key.split(":")[1]) {
              catalogId = this.productList[i].menu1.catagoryId
              filter = this.productList[i].menu1.filter
              tagNameL1 = this.productList[i].menu1.tagName
            }
          }
        }
        this.$router.push({
          path: "/product",
          //设置key防止重复 再截取
          // query: { keyword: value.key.split(":")[1] }
          query:{
            catalogId:catalogId,
            filter:filter,
            tagNameL1:tagNameL1
          }
        });
      } else {
        // 如果选择型号  获取index 筛选的当前选中数据 跳转商品详情
        let index = ''
        if(value.key) {
          index = value.key.split(":")[0]
        }
        let item = this.searchDftListArr[index]
        this.$router.push({
          path: "/product/detail",
          //设置key防止重复 再截取
          query: { productCode: item.productId,orgId:item.orgId }
        });
        // if(index == 0){
        //   this.$router.push({
        //     path: "/product",
        //     //设置key防止重复 再截取
        //     query: { keyword: value.key.split(":")[1] }
        //   });
        // } else {
        //   let item = this.searchDftListArr[index]
        //   this.$router.push({
        //     path: "/product/detail",
        //     //设置key防止重复 再截取
        //     query: { productCode: item.productId,orgId:item.orgId }
        //   });
        // }

      }
    },
    //键盘按下时回调
    inputKeydown(value) {
      // 搜索优化 输入大于等于3个字才调接口
      if(this.searchText.length<=2){
        return
      }
      // 回车搜索
      if (value.keyCode == "13") {
        this.$router.push({
          path: "/product",
          query: { keyword: this.searchText }
        });
      }
    },
    searchFun() {
      // 修改页面跳转 如果输入 点击直接跳转商品列表页面
      if(this.searchText == ''){
        //请输入内容
      }else{
        this.$router.push({
          path: "/product",
          query: { keyword: this.searchText }
        });
      }
      // if (this.searchText) {
      //   if (this.searchDftList && this.searchDftList.length > 0) {
      //     $(".index-search ul li")
      //       .eq(0)
      //       .click();
      //   }
      //
      // } else {
      //   // if()
      //   // this.handleSelect(this.searchText);
      // }

      // if (this.searchDftList == this.searchDftListAll) {
      //   this.$router.push({
      //     path: "/product",
      //     query: { tagName: this.searchVal.key }
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/product",
      //     query: { productId: this.searchVal.key }
      //   });
      // }
    },
    //获取列表
    getProductSearch(val) {
      this.searchLoading = true;
      let params = {
        key: val
        // simpleSearch:'Y'
        // pageNum:1,
        // pageSize:16
      };
      wordSearchNew(params)
        .then(res => {
          //增加展示输入的当前项的 标识
          let addFlag = false;
          for(let i = 0;i<res.data.length;i++) {
            if (res.data[i].zzprdmodel == val) {
              break;
            } else {
              addFlag = true;
            }
          }

          // for (const item of res.data) {
          //   if (item == val) {
          //     break;
          //   } else {
          //     addFlag = true;
          //   }
          // }
          // if (addFlag) {
          //   res.data.unshift({zzprdmodel:val});
          // }
          //空值不查询
          if (this.isSearchStart) {
            return;
          }
          this.searchDftListArr = res.data
          this.searchDftList = res.data.map(it=>it.zzprdmodel);
          this.searchLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.searchLoading = false;
        });
      // console.log(productSearch);
      // productSearch(params)
      //   .then(res => {
      //     console.log(res);
      //     const list = res.data.list.map(item => ({
      //       key: item.id,
      //       label: item.upCatalogName + " " + item.productName
      //     }));
      //     console.log(list);
      //     this.searchDftList = list;
      //     this.searchLoading = false;
      //   })
      //   .catch(error => {
      //     console.log(error);
      //     this.searchLoading = false;
      //   });
    },
    itemClick(e, val) {
      if (this.menuList[val].url == "/") {
        return;
      }
      // 初始化所有菜单的样式
      this.menuList.forEach(el => {
        el.active = false;
      });
      // // 改变当前活动模块样式
      // this.menuList[val].active = true;
      // if (val > 0) {
      //   let line_left = this.menuList[val - 1];
      //   line_left.type == "line" ? (line_left.active = true) : "";
      // }
      // if (val < 14) {
      //   let line_right = this.menuList[val + 1];
      //   line_right.type == "line" ? (line_right.active = true) : "";
      // }
    }
  }
};
</script>

<style lang="less">
.fadeUp-enter,
.fadeUp-leave-to {
  opacity: 0;
}
.fadeUp-active,
.fadeUp-leave-active {
  transition: opacity 3s;
}
@import "../../styles/common.less";
.search-list {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  top: 99px !important;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #f7f7f7 !important;
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #f7f7f7 !important;
  }
  .ant-select-dropdown-menu-item-selected {
    background-color: #f7f7f7 !important;
  }
}
.page-menu {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  min-width: 1188px;
  height: 74px;
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
  // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.17);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 101;
  .menu-in {
    width: 1188px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    position: relative;
    .animated {
      animation-duration: 0.3s;
      &.fadeOut,
      &.fadeOutUp,
      &.fadeOutLeft {
        // animation-duration: 0.1s;
        animation-duration: 0.1s;
        pointer-events: none;
      }
    }
    .logo-box {
      // width: 92px;
      // height: 54px;
      // margin-top: 10px;
      width: 54px;
      height: 54px;
      border-radius: 6px;
      overflow: hidden;
      background: linear-gradient(180deg, #54d6d2 0%, #00aaa6 100%);
      cursor: pointer;
      position: relative;
      &::before {
        opacity: 1;
        background: url("~@/assets/common/logo-left.png") center center;
        background-size: 100%;
      }
      &::after {
        // background: rgb(35, 212, 74);
        background: url("~@/assets/common/logo-right.png") center center;
        background-size: 100%;
        opacity: 0;
        margin-left: -54px;
      }
      &::after,
      &::before {
        position: absolute;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        left: 0;
        top: 0;
        z-index: 1;
        width: 54px;
        height: 54px;
        content: "";
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }
      &:hover::after {
        opacity: 1;
      }
      &:hover::before {
        opacity: 0;
      }
      &:hover::after,
      &:hover::before {
        -webkit-transform: translate3d(54px, 0, 0);
        transform: translate3d(54px, 0, 0);
      }

      // .site-header .logo:hover:after,
      // .site-header .logo:hover:before {
      //   -webkit-transform: translate3d(55px, 0, 0);
      //   transform: translate3d(55px, 0, 0);
      // }
    }
    .popType1 {
      position: absolute;
      left: 0;
      top: 74px;
      width: 200px;

      z-index: 102;

      // &::before{
      //   content: '';
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   pointer-events: none;
      // }
      &.is-child-show {
        ul {
          border-radius: 0;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
      &.leave {
        ul {
          overflow: hidden;
        }
      }
      ul {
        margin-top: 16px;
        height: 440px;
        // padding-top: 20px;
        background: linear-gradient(90deg, #fbfcfe 0%, #f3f3f4 100%);
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.16),
        -8px 0px 9px 0px rgba(0, 0, 0, 0.04);
        // border-bottom-left-radius: 10px;
        // border-top-left-radius: 10px;
        border-radius: 10px;
        // overflow: hidden;
        position: relative;
        // &:hover,
        // &.whiteBarShow {
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     right: -9px;
        //     width: 10px;
        //     height: 100%;
        //     background: #fff;
        //     z-index: 2;
        //     display: block;
        //     pointer-events: none;
        //   }
        // }
        li {
          height: 44px;
          line-height: 44px;
          position: relative;
          // line-height: 48px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #777;
          font-size: 16px;
          padding-right: 20px;
          cursor: pointer;
          transition: all 0.15s;
          // overflow: hidden;
          &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          &:nth-of-type(10) {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          & + li {
            // margin-top: 10px;
          }
          &:hover,
          &.active {
            background: #ffffff;
            box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.04);
            // &::after {
            //   content: "";
            //   position: absolute;
            //   top: 0;
            //   right: -9px;
            //   width: 10px;
            //   height: 44px;
            //   background: #fff;
            //   z-index: 2;
            //   display: block;
            //   // transition: all .1s;
            // }
          }

          // &.active:before {
          //   transform: scaleX(1);
          // }
          // &:hover:before {
          // }
          // &.fadeOutLeft {
          //   &:before {
          //     background-color: rgba(0, 170, 166, 0);
          //   }
          // }
          // &.hvr-sweep-to-right {
          //   &:before {
          //     transition: all 0.2s;
          //     background-color: rgba(0, 170, 166, 0.6);
          //   }
          //   &.active,
          //   &:hover {
          //     &:before {
          //       background-color: rgba(0, 170, 166, 0.8);
          //     }
          //   }
          // }
          &.not-active {
            &:before {
              background-color: transparent;
            }
          }
          .icon-shouye-gonggao-youdianji {
            font-size: 12px;
            color: #aaa;
            margin-top: 2px;
          }
          span {
            text-indent: 18px;
            // font-weight: 600;
          }
        }
      }
    }

    .popType1Child {
      display: none !important;
      position: absolute;
      left: 200px;
      top: 90px;
      // width: calc(100% - 228px);
      height: 440px;
      background-color: #fff;
      z-index: 101;
      // box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);

      border-radius: 0px 10px 10px 0px;
      // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.04);
      box-shadow: 0px 0 12px rgba(0, 0, 0, 0.08);
      overflow: hidden;
      //遮挡阴影的条 暂时不用
      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 4px;
      //   background-color: #fff;
      //   top: -4px;
      //   left: 0;
      //   pointer-events: none;
      // }
      &.one-line {
        // width: 354px;
        width: 288px;
        ul {
          width: 100%;
          height: 420px;
        }
      }
      &.two-line {
        // width: 662px;
        width: 596px;
        ul {
          width: 100%;
          height: 420px;
        }
      }
      &.three-line {
        width: 838px;
        ul {
          width: 100%;
          height: 420px;
        }
      }
      &.four-line {
        width: calc(100% - 200px);
        ul {
          width: 100%;
          height: 420px;
        }
      }
      &.more {
        ul {
          width: 100%;
          //  max-width: calc(100% - 228px);
          height: auto;
          display: block;
          li {
            float: left;
          }
        }
        // .popType1Child-box {
        //   width: calc(100% - 4px);
        // }
      }

      .popType1Child-box {
        // width: 100%;
        width: calc(100% - 8px);
        height: 420px;
        overflow: auto;
        margin-top: 10px;
        // max-width: calc(100% - 228px);
        /*滚动条样式*/
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: transparent;
        }
      }
      ul {
        // padding: 0px 20px 30px 44px;
        padding: 8px 20px 22px 44px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: -10px;
        li {
          // width: 282px;
          width: 216px;
          height: 48px;
          // float: left;
          // margin-top: 42px;
          // margin-top: 34px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          transition: all 0.15s;
          &:hover {
            // background-color: #f60;
            span {
              font-size: 14px;
              color: #00aaa6;
            }
          }
          img {
            width: 56px;
            height: 56px;
            margin-right: 16px;
          }
          span {
            font-size: 14px;
            color: #262626;
          }
        }
      }
    }

    .popType2 {
      position: absolute;
      left: 0;
      top: 74px;
      max-width: 1188px;

      // height: 200px;
      background-color: #fff;
      z-index: 101;
      // overflow-x: auto;
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.17);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .popType2-box {
        width: 100%;
        height: auto;
        overflow: hidden;
        // overflow-x: auto;
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: transparent;
        }
      }
      .usedListType {
        width: 420px;
        //height: 190px;
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: transparent;
        }
        .usedUl {
          width: 100%;
          padding: 20px 30px 20px 30px!important;
          .usedli {
            // 8个
            .usedol {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              width: 420px;
              height: 140px;
              .lili {
                width:150px;
              }
            }
          }
        }
      }
      .usedListType4 {
        width: 210px;
        //height: 190px;
        &::-webkit-scrollbar {
          width: 6px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: transparent;
        }
        .usedUl {
          width: 100%;
          padding: 20px 30px 20px 30px!important;
          .usedli {
            // 8个
            .usedol {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              width: 420px;
              height: 140px;
              .lili {
                width:150px;
              }
            }
          }
        }
      }

      &.more-style {
        border-radius: 4px;
        &::after {
          content: "";
          position: absolute;
          width: 8px;
          height: 100%;
          // background-color: #fff;
          top: 0;
          right: -8px;
          z-index: 103;
        }
        &::before {
          display: none;
        }
        &.fadeInUp {
          display: block;
        }
        &.fadeOut {
          display: none;
        }
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: #fff;
        top: -6px;
        left: 0;
        pointer-events: none;
      }
      ul {
        .clearfix();
        padding: 22px 40px 30px;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        transition: all 0.2s;
        > li {
          // width: 210px;
          width: 180px;
          height: 100%;
          float: left;
          + li {
            // margin-left: 31px;
            margin-left: 40px;
          }

          h2 {
            .one-hide();
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            color: #262626;
            border-bottom: 1px solid #eee;
            // width: 210px;
            width: 180px;
            cursor: pointer;
          }
          ol {
            // float: left;
            padding-top: 10px;
            li {
              color: #262626;
              // color: #777;
              font-size: 14px;
              width: 100%;
              height: 30px;
              line-height: 30px;
              text-indent: 4px;
              cursor: pointer;
              &:hover {
                background: #f7f7f7;
                color: #00aaa6;
              }
            }
          }
        }
      }
    }
    .usedLLLL {
      margin-left: -180px;
    }
    .usedLLLL4 {
      margin-left: -60px;
    }
    // .search-box {
    //   float: right;
    //   width: 280px;
    //   height: 50px;
    //   .ant-input-group {
    //     height: 100%;
    //     .ant-input {
    //       height: 100%;
    //     }
    //     .ant-input-search-button {
    //       height: 100%;
    //     }
    //   }
    // }
    .search-box {
      width: 278px;
      height: 44px;
      // background: #f7f7f7;
      // border-radius: 2px;
      position: absolute;
      float:right;
      right: 0;
      top: 15px;
      .search-icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 58px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 20px;
        // color: #00aaa6;
        // color: #777;
        color: #262626;
        cursor: pointer;
        transition: all 0.1s;
        &:hover {
          color: #00aaa6;
          // text-shadow: 0 0 30px #00aaa6;
        }
      }
      .ant-select-box {
        float: left;
        width: 278px;
        // height: 50px;
        .ant-select-open {
          // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          .ant-select-selection--single {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
        .ant-select-selection--single {
          border: none !important;
          // background-color: transparent;
          background-color: #f7f7f7;
          height: 44px;
          line-height: 44px;
          box-shadow: none !important;
          border-radius: 4px !important;
        }
        .ant-select-selection__rendered {
          position: relative;
          display: block;
          margin-right: 60px;
          margin-left: 11px;
          line-height: 44px;
        }
        .ant-select-selection__placeholder {
          color: #aaa;
        }
      }
    }
    .menu-box {
      float: left;
      height: 74px;
      line-height: 74px;
      background-color: transparent;
      border: none;
      // width: calc(100% - 400px);
      margin-left: 36px;
      .ant-menu-item,
      .ant-menu-submenu,
      .ant-menu-submenu-title {
        border: none;
        font-size: 16px;
        color: #262626;
        float: left;
        margin: 0;
        top: 0;
        padding: 0 16px;
        position: relative;
        &:hover {
          color: rgba(0, 161, 163, 1) !important;
          &::after {
            content: "";
            position: absolute;
            left: calc(50% - 15px);
            bottom: 0px;
            width: 30px;
            height: 2px;
            background: linear-gradient(180deg, #54d6d2 0%, #00aaa6 100%);
            z-index: 103;
          }
          // background:linear-gradient(225deg,rgba(0,170,166,1) 0%,rgba(0,188,183,1) 100%);
        }
        // &.ant-menu-item-active,
        // &.ant-menu-submenu-active,
        // &.ant-menu-item-open,
        // .ant-menu-horizontal > .ant-menu-submenu-open,
        // &.ant-menu-item-selected,
        // &.ant-menu-submenu-selected {
        //   color: rgba(0, 161, 163, 1) !important;
        //   display: flex;
        //   &::after {
        //     content: "";
        //     left: calc(50% - 15px);
        //     bottom: 0px;
        //     width: 30px;
        //     height: 2px;
        //     background: linear-gradient(180deg, #54d6d2 0%, #00aaa6 100%);
        //     z-index: 103;
        //   }
        // }
      }
      .ant-notification {
        // display: none;
      }
    }
  }
  // > li {
  //   float: left;
  // }
  // .menu-text {
  //   margin-left: 10px;
  //   &:hover {
  //     color: #f60;
  //   }
  //   &.active {
  //     color: greenyellow;
  //   }
  // }
}
</style>
<style lang="less">
script ~ div > div > .ant-menu-submenu-popup {
  // animation-name: fadeInUp;
  // animation-duration: 0.3s;
  // animation-fill-mode: both;
  animation: none;
  transition: none;
  top: 121px !important;
  &.v-leave-active {
    // animation-name: fadeOut;
    // animation-duration: 0.1s;
    animation: none;
    transition: none;
  }
  > ul {
    animation: none;
    transition: none;
  }
}
</style>
<style lang="less">
.cancelNameLogin {
  font-family: PingFangSC-Medium, PingFang SC;
  font-family: PingFangSC-Medium, PingFang SC;
  
  .getPhoneButton{
    margin-left: 10px;
    width: 92px;
    height: 32px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .no-click{
    border: 1px solid #00AAA6;
    color: #00AAA6;
  }
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 100px;
            margin-top: 24px;

            .lable {
              width: 98px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }

            /deep/ .ant-select-selection {
              width: 300px;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .everyBox:first-child{
            margin-top: 70px;
            margin-bottom: 44px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 12px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:20px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}

</style>