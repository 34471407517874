<!--
 * @Author: sqk
 * @Date: 2020-08-10 15:39:59
 * @LastEditTime: 2024-03-13 14:43:31
 * @LastEditors: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\layout\TopDownLayout\Footer.vue
-->

<template>
  <div class="page-footer">
    <div class="foot-top">
      <ul>
        <li v-for="(item,index) in footerDataList" :key="index">
          <h3>{{ item.title }}</h3>
          <ol v-for="(item2,index2) in item.urlList" :key="index2">
            <li @click="toUrl(item2)">{{ item2.name }}</li>
          </ol>
        </li>
      </ul>
      <!-- 去掉联系我们一整块 -->
      <!-- <div class="info-box">
        <h4 class="tel">4006-111-111</h4>
        <p>周一至周日 24小时</p>
        <div class="service">
          <i class="iconfont icon-yijianjubao-moren"></i>
          人工客服
        </div>
      </div> -->
       <!-- <a-button icon="user-delete">人工客服</a-button> -->
    </div>
    <div
      class="foot-btm"
    >Copyright 2021 Hisense Group Holdings Co., Ltd. All Rights Reserved. 海信集团控股股份有限公司版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2023051506号-12A</a>  </div>
  </div>
</template>

<script>
import { defaultImgPath, jumpURL } from "@/common/constant.js";
export default {
  name: "Footer",
  data() {
    return {
      footerDataList: [
        {
          title: "购物指南",
          urlList: [
            {
              name: "如何下单",
              url: "/center/operatingManual?type=xd"
            },
            {
              name: "支付方式",
              url: "/center/operatingManual?type=cw"
            },
            {
              name: "法律声明",
              url: "/footerLinke/homeLegalStatement"
            },
            {
              name: "隐私政策",
              url: "/privacy"
            },

          ]
        },
        {
          title: "服务与支持",
          urlList: [
            {
              name: "百问百答",
              url: "/center/operatingManual?type=bdbk"
            },
            {
              name: "如何销售",
              url: "/center/operatingManual?type=xs"
            },
            {
              name: "库存问题",
              url: "/center/operatingManual?type=kc"
            },
            {
              name: "财务问题",
              url: "/center/operatingManual?type=cw"
            },
            // {
            //   name: "其他模块",
            //   url: "/center/operatingManual?type=qt"
            // },
            // {
            //   name: "常见问题",
            //   url: "/footerLinke/homeCommonProblem"
            // },
            // {
            //   name: "售后服务",
            //   url: "https://mall.hisense.com/pub/articles/list?menuId=22"
            // },
            // {
            //   name: "培训发展",
            //   url: ""
            // },
            // http://b2b-front-cis.devapps.hisense.com/footerLinke/homeService.htm
            // {
            //   name: "廉政举报",
            //   url: "/footerLinke/loginMessageProbabilityCheck"
            // }
          ]
        },
        {
          title: "加盟我们",
          urlList: [
            {
              name: "加盟专卖店",
              url: jumpURL + "specialShop/apply.htm?source=b2b"
            },
            {
              name: "加盟经销商",
              // url: jumpURL + "IntentionCust/apply.htm?source=b2b"
              url:'#/intendedMerchant'
            }
          ]
        },
        {
          title: "关于海信",
          urlList: [
            {
              name: "企业简介",
              url: "https://www.hisense.com/introduce/index.aspx?nodeid=18"
            },
            {
              name: "企业荣誉",
              url: "https://www.hisense.com/layout/index.aspx?nodeid=21"
            },
            {
              name: "企业布局",
              url: "https://www.hisense.com/honor2/index.aspx?nodeid=83"
            },
            // {
            //   name: "公司新闻",
            //   url: "http://www.hisense.cn/news/index.aspx?nodeid=16"
            // },

            // {
            //   name: "联系我们",
            //   url: "/footerLinke/homeContactUs"
            // }
          ]
        }
      ]
    };
  },
  methods: {
    toUrl(item) {
      if (
        item.name == "培训发展" ||
        item.name == "加盟专卖店" ||
        item.name == "加盟经销商" ||
        item.name == "公司新闻" ||
        item.name == "售后服务"||
        item.name == "企业简介"||
        item.name == "企业荣誉"||
        item.name == "企业布局"
      ) {
        window.open(item.url, "_blank");
      } else {

        this.$router.push({
          path: item.url
        });
      }
    }
  }
};
</script>

<style lang="less">
.page-footer {
  width: 100%;
  height: 330px;
  background: rgba(255, 255, 255, 1);
  // position: fixed;
  // bottom: 0;
  // position: absolute;
  // left: 0;
  // bottom: 0;

  .foot-top {
    width: 1200px;
    height: 290px;
    margin: 0 auto;
    // padding-left: 293px;
    display: flex;
    justify-content: center;
   
    ul {
      text-align: left;
      float: left;
      // 添加距离左边距
       margin-left: 60px;
    }
    ul > li {
      float: left;
      width: 177px;
      margin-top: 73px;

      h3 {
        line-height: 1;
        font-size: 16px;
        color: rgba(38, 38, 38, 1);
        margin-bottom: 14px;
      }

      ol {
        li {
          font-size: 14px;
          color: rgba(119, 119, 119, 1);
          cursor: pointer;
          line-height: 28px;
          &:hover {
            color: rgba(0, 161, 163, 1);
          }
        }
      }
    }
    .info-box {
      margin-top: 72px;
      float: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 {
        font-size: 22px;
        color: rgba(0, 161, 163, 1);
        line-height: 1;
      }
      p {
        font-size: 14px;
        color: rgba(119, 119, 119, 1);
        line-height: 38px;
      }
      .service {
        cursor: pointer;
        width: 120px;
        height: 28px;
        line-height: 26px;
        border: 1px solid #00aaa6;
        font-size: 12px;
        font-weight: 500;
        color: #00aaa6;
        transition: all 0.3s;
        i {
          font-size: 14px;
          color: #00aaa6;
          margin-right: 4px;
          transition: all 0.3s;
        }
        &:hover {
          // border: 1px solid #fff;
          background-color: #00aaa6;
          color: #fff;
          i {
            color: #fff;
          }
        }
      }
    }
  }
  .foot-btm {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: rgba(63, 63, 63, 1);
    color: rgba(204, 204, 204, 1);
    font-size: 12px;
    a{
      color: #00B7B3;
    }
  }
}
</style>
