<template>
    <div>
        <a-modal v-model="visible" @ok="handleOk" @cancel="handleCancel" okText="立即登录" wrapClassName="cancelNameLogin"
            :width="648" :closable="false" centered :maskClosable="false">
            <a-spin :spinning="o2oLoadFlag">
                <div class="modal_close" @click="handleCancel">
                    <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
                </div>
                <div class="modal_title_box">
                    <span class="modal_title">
                        <i class="modal_title_line"></i>请选择门店
                    </span>
                </div>
                <a-form-model style="margin-top:30px;">
                    <div class="long"
                        style="margin-bottom:24px;">
                        <div style="display: flex;justify-content: center;align-items: center">
                            <span
                                style="width:95px;text-align:right;margin-right:8px;color:#262626;font-size:14px;font-weight: 400">门店名称/编码:</span>
                            <a-select style="width:300px" placeholder="请选择"
                                show-search v-model="selectStore" :filterOption="false" @search="handleSearchClick">
                                <a-select-option :value="item.cisId" v-for="item in storeFilterList" :key="item.cisId">
                                    {{item.name}}-{{item.cisCode}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div v-if="showCode" class="long"
                        style="margin-bottom:24px">
                        <div style="display: flex;justify-content: center;align-items: center">
                            <span
                                style="width:95px;text-align:right;margin-right:8px;color:#262626;font-size:14px;font-weight: 400">验证码:</span>
                            <a-input style="width:198px" placeholder="请输入" v-model="loginCode" />
                            <a-button :class="['getPhoneButton', codeDisabled ? '' : 'no-click']" :disabled="codeDisabled"
                                @click="getPhoneCode">{{snedCOde}}</a-button>
                        </div>
                        <span v-show="errorCode"
                            style="margin-left:-108px;font-size:12px;color: #D9161C;margin-top: 4px">您输入的有误！</span>
                    </div>
                </a-form-model>
                <div v-if="showCode" :class="['message-text', snedCOde == '获取验证码' ? '' : 'message-text-green']">
                    {{ `${snedCOde == '获取验证码' ? '将发送至绑定手机号' : ' 已发送至'}${String(storeList.find(el => el.cisId == selectStore).username).substr(0, 3)}****${String(storeList.find(el => el.cisId == selectStore).username).substr(7, 11)}` }}
                </div>
            </a-spin>
        </a-modal>
        <HisenseAgreement
            :visible.sync="o2oAgreementVisible"
            :cacheSign="cacheSign"
        />
    </div>
</template>
<script>
import {
    getshopLogin,
    getShopLoginCode,
    checkShopLoginCode,
    getshopStore
} from "@/utils/api.js";
import { env } from "@/common/constant";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selectStore: undefined, // 选择的门店
            storeList: [], // 门店列表
            storeFilterList: [], // 过滤后的门店列表
            errorCode: false, // 验证码错误
            loginCode: null, // 登录验证码
            snedCOde: "获取验证码",
            codeDisabled: false,
            o2oLoadFlag: false, // o2o中心弹窗loading
            time: 60,
            codeTime: null,
            o2oAgreementVisible: false, // 登录海信商城后  如果用户未开户弹出
            cacheSign: null, // 跳转海信商城数据
        }
    },
    computed: {
        showCode(){
            if(this.$store.state.user.userInfo.account.type == "main" || !this.selectStore){
                return false;
            }else{
                if(this.chekcIsLogin(this.storeList.find(item => item.cisId == this.selectStore), this.$store.state.user.userInfo.account.account)){
                    return false;
                }else{
                    return true;
                }
            }
        }
    },
    methods: {
        // 检查是否登陆过  非管理员账户使用
        chekcIsLogin(obj, id){
            if(obj.extra && obj.extra.loggedInAccounts){
                const arr = JSON.parse(obj.extra.loggedInAccounts);
                if(arr && arr.findIndex(item => item.logged == id) > -1){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        handleSearchClick(value) {
            this.storeFilterList = JSON.parse(JSON.stringify(this.storeList.filter(el => el.name?.indexOf(value) > -1 || el.cisCode?.indexOf(value) > -1 || el.username?.indexOf(value) > -1 || el.outerId?.indexOf(value) > -1)));
        },
        getPhoneCode() {
            if (!this.selectStore) {
                return this.$message.warning("请先选择门店");
            }
            const phone = this.storeList.find(item => item.cisId == this.selectStore).username;
            getShopLoginCode(phone).then(() => {
                this.time = 60;
                this.codeDisabled = true;
                this.codeTime = setInterval(() => {
                    if (this.time == 0) {
                        clearInterval(this.codeTime);
                        this.codeTime = null
                        this.codeDisabled = false;
                        this.snedCOde = "获取验证码";
                        this.time = 60;
                        return;
                    }
                    this.time--;
                    this.snedCOde = this.time + "s" + '重新获取';
                }, 1000);
            })
        },
        // 立即登录
        handleOk() {
            if (!this.selectStore) {
                return this.$message.warning("请先选择门店")
            }
            if (!this.loginCode && this.showCode) {
                return this.$message.warning("请输入验证码")
            }
            this.o2oLoadFlag = true
            const phone = this.storeList.find(item => item.cisId == this.selectStore).username;
            const isOpenAccountSuccess = this.storeList.find(item => item.cisId == this.selectStore).isOpenAccountSuccess;
            const auditStatus = this.storeList.find(item => item.cisId == this.selectStore).auditStatus;
            if(this.showCode){
                checkShopLoginCode(phone, this.loginCode).then(res => {
                    if (res.data.code == 0) {
                        getshopLogin(this.$store.state.user.userInfo.customer.id, this.selectStore, this.$store.state.user.userInfo.account.account).then(result => {
                            this.o2oLoadFlag = false
                            this.$emit("update:visible", false);
                            if(isOpenAccountSuccess == "1" || auditStatus == "1"){
                                window.open(`${env == "dev" || env == "test" || env == "uat" ? 'https://test.shop.hisense.com' : 'https://mall.hisense.com'}/xtwLogout?cacheSign=${result.data.extra.accessToken}&type=${this.$store.state.user.userInfo.account.type}&ciscustomerid=${this.$store.state.user.userInfo.customer.id}`);
                            }else{
                                this.cacheSign = result.data.extra.accessToken;
                                this.o2oAgreementVisible = true;
                            }
                        }).catch(() => this.o2oLoadFlag = false)
                    } else {
                        this.errorCode = true;
                        this.o2oLoadFlag = false;
                    }
                }).catch(() => this.o2oLoadFlag = false);
            }else{
                getshopLogin(this.$store.state.user.userInfo.customer.id, this.selectStore, this.$store.state.user.userInfo.customer.cisCode).then(result => {
                    this.o2oLoadFlag = false
                    this.$emit("update:visible", false);
                    if(isOpenAccountSuccess == "1" || auditStatus == "1"){
                        window.open(`${env == "dev" || env == "test" || env == "uat" ? 'https://test.shop.hisense.com' : 'https://mall.hisense.com'}/xtwLogout?cacheSign=${result.data.extra.accessToken}&type=${this.$store.state.user.userInfo.account.type}&ciscustomerid=${this.$store.state.user.userInfo.customer.id}`);
                    }else{
                        this.cacheSign = result.data.extra.accessToken;
                        this.o2oAgreementVisible = true;
                    }
                }).catch(() => this.o2oLoadFlag = false)
            }
        },
        handleCancel() {
            this.$emit("update:visible", false);
            // this.$emit("cancel");
            this.loginCode = null;
        },
    },
    mounted() {
        getshopStore(this.$store.state.user.userInfo.customer.id).then(result => {
            this.storeList = JSON.parse(JSON.stringify(result.data));
            this.storeFilterList = JSON.parse(JSON.stringify(result.data));
        })
    }
}
</script>
<style lang="less">
.cancelNameLogin {
    font-family: PingFangSC-Medium, PingFang SC;
    .message-text{
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #AAAAAA;
    }
    .message-text-green{
        color: #00AAA6;
    }
    .getPhoneButton {
        margin-left: 10px;
        width: 92px;
        height: 32px;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: #AAAAAA;
        padding: 0;
    }

    .no-click {
        border: 1px solid #00AAA6;
        color: #00AAA6;
    }

    .ant-modal-content {
        text-align: center;
        border-radius: 10px;

        .ant-modal-body {
            padding: 30px 0px 0 0px;

            .cancelContent {
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                color: #262626;
                line-height: 20px;


                .selectBox {
                    //margin-top: 16px;
                    display: flex;
                    flex-direction: column;

                    .everyBox {
                        display: flex;
                        align-items: center;
                        margin-left: 100px;
                        margin-top: 24px;

                        .lable {
                            width: 98px;
                            margin-right: 8px;
                            text-align: right;
                            font-size: 14px;
                            color: #262626;

                            span {
                                font-size: 14px;
                                font-weight: 400;
                                color: #D9161C;
                            }
                        }

                        /deep/ .ant-select-selection {
                            width: 300px;
                            height: 32px;
                            background: #FFFFFF;
                            border-radius: 4px;
                            border: 1px solid #EEEEEE;
                        }

                        .aInput {
                            width: 416px !important;
                            height: 32px !important;
                            background: #FFFFFF;
                            border-radius: 4px;
                            border: 1px solid #EEEEEE;
                            color: #262626;
                        }

                        .userInput {
                            width: 200px;
                            margin-right: 16px;
                        }

                        .radioGroup {
                            margin-left: 8px;
                        }

                        .userSorce {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            //align-items: center;
                            max-width: 530px;

                            .smallSorce {
                                padding: 0 14px 0 14px;
                                color: #00AAA6;
                                height: 32px;
                                border-radius: 16px;
                                border: 1px solid #EEEEEE;
                                text-align: center;
                                line-height: 32px;
                                margin-right: 8px;
                                margin-bottom: 12px;
                                cursor: pointer;
                            }

                            .smallSorceActice {
                                cursor: pointer;
                                height: 32px;
                                background: #00AAA6;
                                border-radius: 16px;
                                text-align: center;
                                line-height: 32px;
                                padding: 0 14px 0 14px;
                                color: #fff;
                                margin-right: 8px;
                                margin-bottom: 12px;
                                //border: none;
                            }
                        }

                        .circleBox {
                            width: 32px;
                            height: 32px;
                            border-radius: 16px;
                            border: 1px solid #EEEEEE;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            img {
                                width: 12px;
                                height: 12px;
                            }
                        }

                    }

                    .everyBox:first-child {
                        margin-top: 70px;
                        margin-bottom: 44px;
                    }

                    .proBox {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start !important;
                        align-items: flex-start;

                        .prooneBox {
                            display: flex;

                            .prouctBox {
                                display: flex;
                                flex-direction: column;

                                .proItem {
                                    display: flex;
                                    margin-bottom: 16px;
                                    align-items: center;

                                    /deep/ .ant-select-selection {
                                        width: 180px;
                                        height: 32px;
                                        background: #FFFFFF;
                                        border-radius: 4px;
                                        border: 1px solid #EEEEEE;
                                    }

                                }
                            }
                        }

                        .addNEWbTN {
                            cursor: pointer;
                            width: 180px;
                            height: 32px;
                            border-radius: 3px;
                            border: 1px dashed #EEEEEE;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #1890FF;
                            margin-left: 332px;

                            img {
                                width: 12px;
                                height: 12px;
                                margin-right: 4px;
                            }
                        }

                    }

                    .biaoqian {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .biaoqianBox {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start
                        }

                        .tips {
                            font-size: 12px;
                            font-weight: 400;
                            color: #AAAAAA;
                            margin-left: 109px;
                            margin-top: -6px;
                        }

                    }

                    .laiyuan {
                        margin-top: 12px;
                    }

                    .adress {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        div {
                            display: flex;
                        }

                        .userDsInputress {
                            margin-top: 16px;
                            margin-left: 106px;
                            width: 500px;
                        }
                    }
                }


                .ant-tree-node-selected {
                    background-color: red;
                }

                .ant-form {
                    line-height: 32px;
                }

                .ant-form-item-control-wrapper {
                    margin-left: 16px;
                }

                .ant-form-item-control {
                    line-height: 32px;
                }

                .ant-form-item-label {
                    line-height: 32px;
                }

                .ant-form-item {
                    margin-bottom: 0;
                }

                .messageInfo {
                    font-size: 12px;
                    font-weight: 400;
                    color: #AAAAAA;
                    line-height: 12px;
                    margin-top: 8px;
                    margin-left: 86px;
                }
            }



            .modal_close {

                position: absolute;
                width: 42px;
                height: 42px;
                top: -30px;
                right: 0;
                line-height: 42px;
                border-radius: 0 10px 0 0;
                text-align: center;
                cursor: pointer;

                .modal_close_icon {
                    color: #aaa;
                    font-size: 16px;
                }
            }

            .modal_close:hover {
                background: #E4F8F7;

                .modal_close_icon {
                    color: #08ABA8;
                }
            }

            .modal_title_box {
                text-align: center;
                //padding-bottom: 50px;

                .modal_title {
                    position: relative;
                    color: #262626;
                    text-align: center;
                    z-index: 10;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 1px;

                    .modal_title_line {
                        display: inline-block;
                        width: 100%;
                        position: absolute;
                        top: 13px;
                        left: 0;
                        border-top: 10px solid #E4F8F7;
                        z-index: -10;
                    }
                }
            }
        }

        p {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
            line-height: 30px;
        }

        .ant-modal-footer {
            text-align: center;
            border-top: 0px solid #fff;
            padding: 16px 0px 30px 0;

            .ant-btn {
                width: 120px;
                height: 40px;
                border-radius: 4px;
                font-size: 16px;
                color: #777;
            }

            .ant-btn-primary {
                color: #fff;
                background: #00AAA6;
                margin-left: 24px;
            }
        }
    }
}
</style>